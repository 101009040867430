import React from 'react';
import { Helmet } from 'react-helmet';
import { MDBBtn, MDBContainer, MDBCol, MDBRow, MDBCardBody, MDBIcon, MDBCard } from 'mdb-react-ui-kit';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsCaretUpFill } from 'react-icons/bs';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { FeaturedLotes } from '../components/featuredLotes';
import { Beneficios } from '../components/beneficios';
import './HomePage.css';
import LoteadorasImg from '../img/loteadoras.jpeg';
import BlogImg from '../img/blog.jpeg';
import NavBar from '../components/navbar';
import Footer from '../components/footer';

const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

class HomePage extends React.Component {
  constructor(props) {
    super(props);       
    this.state = {
      cidade: '',
    }; 
  }
 
  componentDidMount(){
    this.cidadesCharger();
  }


  cidadesCharger = () => {
    fetch(api + "cidades")
    .then((response) => response.json())
    .then((response) => {        
      this.setState({cidade: response});
    })
    .catch((rejected) => {
      console.log(rejected);
    }); 
  }

  irpara(link, value){
    if(value !== ''){
    window.location.href = link+value;
    }else{
      window.location.href = link;
    }
  }


  render() {
    return (
      <>
        <Helmet>
          <meta name="description" content={"Encontre o imóvel perfeito com a Imóvel Valoriza! Somos a imobiliária digital que oferece venda e aluguel de casas, apartamentos, terrenos, propriedades rurais e comerciais no interior de São Paulo. Descubra o lar dos seus sonhos agora mesmo!"} />
        </Helmet>      
        <NavBar />
        <section className='background-home'>
          <div className='background-gradient'>
          <MDBContainer>
            <MDBRow style={{ padding: '0 1rem 0 1rem'}} className='d-flex align-items-center banner-responsive'>
              <MDBCol md='3'>
                <h1 style={{fontWeight: 700, color: '#f5f5f5', fontSize: '2.3rem'}}>Onde você quer morar? </h1>
                <MDBRow style={{backgroundColor: '#fff', borderRadius: '30px', marginTop: '1rem', padding: '0.2rem 0 0.2rem 0',}} className='justify-content-right'>
                  <MDBCol size={7}>
                    <select id='location' defaultValue='null' className="form-control select-ios" style={{marginLeft: '-0.5rem', width: '150%', border: 'none', fontSize: '1.1rem', display: 'inline', borderRadius: '30px 0px 0px 30px', fontWeight: 500}}>
                        <option value="null" className='card' disabled>escolha a cidade</option>
                        {this.state.cidade.result && (      
                          <>
                            {this.state.cidade.result.map((data) => (
                              <option value={data.CIDADE} key={data.CIDADE} className='card'>{data.CIDADE}</option>
                            ))}
                          </>
                        )}  
                    </select>
                  </MDBCol>
                  <MDBCol size={5} style={{ paddingRight: '0.3rem', }}>
                    <MDBBtn onClick={() => this.irpara('buscar/', (document.getElementById('location').value !== 'null' ? 'all/all/' + document.getElementById('location').value : ''))} style={{padding: '0.5rem', width: '100%', borderRadius: '30px', fontSize: '0.8rem', backgroundColor: '#65005a', fontWeight: 600, textTransform: 'initial',}}>
                      <MDBIcon icon="search-location" /><AiOutlineSearch size={'1rem'} />&nbsp; Buscar</MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          </div>
        </section>
        <section>
          <MDBContainer style={{width: '90%'}}>
            <h1 className="text-center" style={{fontWeight: 700, marginTop: '3rem'}}> 
              Procurando por novidades?
            </h1>
            <p className="w-responsive text-center mx-auto mb-5" style={{fontWeight: 500, fontSize:'1rem', marginBottom: '0'}}>
              A Imóvel Valoriza conecta você aos melhores imóveis da cidade.
            </p>
            <FeaturedLotes />
          </MDBContainer>
        </section>
        <section>
          <MDBContainer style={{width: '90%'}}>
            <MDBRow>
              <MDBCol>
                <h2 className="text-center" style={{fontWeight: 700, marginTop: '5rem'}}> 
                  Jeito fácil de comprar seu imóvel
                </h2>
                <p className="w-responsive text-center mx-auto mb-5" style={{fontWeight: 500, fontSize:'1rem'}}>
                  Um especialista da Imóvel Valoriza guiará você durante todo o processo.
                </p>
              </MDBCol>
            </MDBRow>
            <Beneficios />
          </MDBContainer>
        </section>
        <section style={{marginTop: '5rem'}}>
          <MDBContainer style={{width: '90%', marginBottom: '2rem',}}>
            <MDBRow style={{background: '#f7f7f7'}} className='justify-content-center'>
              <MDBCol md={6} className='custom-secao-home'>
                <p style={{fontWeight: 700, fontSize: '0.88rem', textTransform: 'uppercase', color: '#65005a'}}>
                  <BsCaretUpFill className='icon-rotated' color={'#65005a'}/>
                  <br />
                  Para Incorporadores</p>
                <h4 style={{fontWeight: 700, marginTop: '1.5rem'}}> 
                  Uma house focada no seu empreendimento
                </h4>
                <p style={{fontWeight: 500, fontSize:'1rem', marginTop: '1rem', textAlign: 'initial'}}>
                  Híbrida e altamente treinada: 
                  você merece ter uma equipe de alta performance para trabalhar 
                  seus estoques e lançamentos.                  
                </p>
                <MDBBtn onClick={() => window.location.href = '/incorporador'} style={{backgroundColor: '#ff7300', fontWeight: 600, marginTop: '1rem'}}>Quero! {'>>'}</MDBBtn>
              </MDBCol>
              <MDBCol md={6} style={{margin: 0, padding: 0}} className='exc-mobile'>
                  <img src={LoteadorasImg} className='img-fluid float-end' alt='imagem ilustrativa da sessão para incorporadores.'/>
              </MDBCol>
            </MDBRow>            
          </MDBContainer>          
        </section>
        <section style={{marginTop: '5rem', marginBottom: '5rem'}}>
          <MDBContainer style={{width: '90%', marginBottom: '2rem',}}>
            <MDBRow style={{background: '#f7f7f7'}} className='justify-content-center'>
              <MDBCol md={6} className='custom-secao-home'>
                <p style={{fontWeight: 700, fontSize: '0.88rem', textTransform: 'uppercase', color: '#65005a'}}>
                  <BsCaretUpFill className='icon-rotated' color={'#65005a'}/>
                  <br />
                  Para Você </p>
                <h4 style={{fontWeight: 700, marginTop: '1.5rem'}}> 
                  Fique por dentro de tudo, conheça o blog da Imóvel Valoriza
                </h4>
                <p style={{fontWeight: 500, fontSize:'1rem', marginTop: '1rem', textAlign: 'initial'}}>
                  Acompanhe em primeira mão as notícias e atualizações mais relevantes sobre o mercado.                  
                </p>
                <MDBBtn onClick={() => window.location.href = 'https://blog.imovelvaloriza.com.br'} style={{backgroundColor: '#ff7300', fontWeight: 600, marginTop: '1rem'}}>Blog {'>>'}</MDBBtn>
              </MDBCol>
              <MDBCol md={6} style={{margin: 0, padding: 0}} className='exc-mobile'>
                  <img src={BlogImg} className='img-fluid float-end' alt='imagem ilustrativa da sessão para incorporadores.'/>
              </MDBCol>
            </MDBRow>            
          </MDBContainer>          
        </section>
        <Footer />
      </>
    );
  }
}

export default HomePage;
