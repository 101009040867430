import React from 'react';
import { Helmet } from 'react-helmet';
import { MDBBtn, MDBContainer, MDBCol, MDBRow, MDBCardBody, MDBCard, } from 'mdb-react-ui-kit';
import { TiFlashOutline } from 'react-icons/ti';
import { BiMessageSquareCheck } from 'react-icons/bi';
import { BsPersonFill, BsWhatsapp } from 'react-icons/bs';
import { IoPhonePortraitSharp } from 'react-icons/io5'
import { MdAlternateEmail, MdShareLocation, MdVerified, MdOutlineShare } from 'react-icons/md';
import { GoVerified } from 'react-icons/go';
import { AiOutlineAlignLeft, AiOutlineCheck } from 'react-icons/ai'; 
import { CgSandClock } from 'react-icons/cg';
import './HomePage.css';
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import Loader from '../components/loader';

const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

class Vender extends React.Component {
  constructor(props) {
    super(props);   
    document.title = 'Venda seu imóvel com facilidade e eficiência com a Imóvel Valoriza';

    this.state = {
      formstep: 'initial',
      nome: '',
      email: '',
      telefone: '',
      cidade: '',
      info: '',
     };
  }

  enviarContato = () => {
    
    this.setState({formstep: 'sending'});

    const dataToSend = {
      nome: this.state.nome,
      email: this.state.email,
      telefone: this.state.telefone,
      cidade: this.state.cidade,
      info: this.state.info,
      tipo: "site"
    };
    
    const options = {
      method: "POST",
      body: JSON.stringify(dataToSend),
      mode: 'cors'
    };
    
    fetch(api + 'enviar-email', options)
      .then(response => response.json())
      .then(data => data.status ? this.setState({formstep: 'final'}) : this.setState({formstep: 'error'}))
      .catch(error => console.error(error));
  }

  render() {
    return (
      <>
        <Helmet>
          <meta name="description" content={"Conheça os motivos para vender seu imóvel com a Imóvel Valoriza. Utilizamos tecnologia digital para alcançar mais clientes e contamos com especialistas que conectam você aos maiores corretores do mercado. Cuidamos de todas as etapas do processo de venda. Venda seu imóvel com a Imóvel Valoriza agora!"} />
        </Helmet>  
        <NavBar />
        <div className='background-vender'>
          <MDBContainer
            style={{ height: '100%', width: '100%', paddingTop: '8.5rem',paddingBottom: '3rem' }}
            className='d-flex justify-content-center align-items-center'
          >
            <MDBRow>
              <MDBCol md='12' className='text-center mt-xl-5 mb-5'>
                <h1 className='display-4' style={{fontWeight: 700, color: '#f5f5f5'}}>Venda seus imóveis aqui!</h1>
                <h6 className='mb-4 mt-4' style={{fontWeight: 700, color: '#f5f5f5'}}>
                  Cadastre-se para transformar seus imóveis em dinheiro.
                </h6>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>      

   <section style={{backgroundColor: '#ff6f01', paddingTop: '3rem', paddingBottom: '3rem'}}>
        <p className="deep-orange-text w-responsive text-center mx-auto mb-3" style={{fontWeight: 700, fontSize:'0.8rem', textTransform: 'uppercase'}}>
        <a id='conhecer' href="#conhecer" style={{textDecoration: 'none', color: '#f5f5f5'}}><MdVerified style={{fontSize: '1.5rem', color: "#65005A"}}/>&nbsp; Como funciona</a>
        </p>
        <h2 className="h1-responsive text-center my-4" style={{fontWeight: 600, color: '#f5f5f5'}}> 
          Motivos para vender com a <br /><span style={{color: '#f5f5f5', fontWeight: 700}}>Imóvel Valoriza</span>
        </h2>
        <p className="w-responsive text-center mx-auto mb-5" style={{fontWeight: 600, color: '#f5f5f5', fontSize:'0.8rem', paddingLeft: '0.7rem', paddingRight: '0.7rem'}}>
           Conheça os motivos porque você deveria vender com a Imóvel Valoriza.
        </p>

        <MDBRow className='justify-content-center align-items-center' style={{marginLeft: '1rem', marginRight: '1rem'}}>
          <MDBCol md='4' className="md-0 mb-5 ">
            <MDBCard className='' style={{borderRadius: '30px'}}>
              <MDBCardBody className='text-center'>
                <MDBRow>
                  <MDBCol size={3}>
                    <div className='d-flex align-items-center' style={{height: '100%'}}>
                      <div className='icon-circle'>
                        <span>
                          <MdAlternateEmail style={{fontSize: '2rem', color: '#f5f5f5'}} className='mb-4 mt-4'/>
                        </span>
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol size={9}>
                    <h4 className="mb-3 mt-1" style={{textAlign: 'left', fontWeight: '600'}}>Canal Digital</h4>
                    <p style={{marginBottom: '1.3rem', textAlign: 'left', fontWeight: '500'}}>
                        Com tecnologia digitalizamos as vendas para alcançar mais clientes.
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md='4' className="md-0 mb-5 ">
            <MDBCard className='' style={{borderRadius: '30px'}}>
              <MDBCardBody className='text-center'>
                <MDBRow>
                  <MDBCol size={3}>
                    <div className='d-flex align-items-center' style={{height: '100%'}}>
                      <div className='icon-circle'>
                        <span>
                          <GoVerified style={{fontSize: '2rem', color: '#f5f5f5'}} className='mb-4 mt-4'/>
                        </span>
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol size={9}>
                    <h4 className="mb-3 mt-1" style={{textAlign: 'left', fontWeight: '600'}}>Especialistas</h4>
                    <p style={{marginBottom: '1.3rem', textAlign: 'left', fontWeight: '500'}}>
                      Um elo entre uma plataforma exclusiva e os maiores corretores do mercado.
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md='4' className="md-0 mb-5 ">
            <MDBCard className='' style={{borderRadius: '30px', paddingBottom: '0.75rem', paddingTop: '0.75rem'}}>
              <MDBCardBody className='text-center'>
                <MDBRow>
                  <MDBCol size={3}>
                    <div className='d-flex align-items-center' style={{height: '100%'}}>
                      <div className='icon-circle'>
                        <span>
                          <MdOutlineShare style={{fontSize: '2rem', color: '#f5f5f5'}} className='mb-4 mt-4'/>
                        </span>
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol size={9}>
                    <h4 className="mb-3 mt-1" style={{textAlign: 'left', fontWeight: '600'}}>Ponta a ponta</h4>
                    <p style={{marginBottom: '1.3rem', textAlign: 'left', fontWeight: '500'}}>
                      Cuidamos de todas as etapas do processo.
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
    </section>
    <section className="my-3">
      <MDBContainer>
                { this.state.formstep === 'initial' ?
                  <>
                  <MDBRow>
                    <MDBCol style={{paddingTop: '2.5rem',}}>
                      <div className='text-center'>
                        <div className='d-flex justify-content-center'>
                          <div className='icon-circle'>
                              <span>
                                <TiFlashOutline style={{fontSize: '2rem', color: '#f5f5f5'}} />
                              </span>
                            </div>                
                        </div>
                        <p style={{fontWeight: '700', fontSize: '0.9rem', color: '#212121', marginTop: '1.5rem', marginBottom: '1.5rem', color: "#65005A", }}><u>Rápido e Prático</u></p>
                        
                        <h2 className="h1-responsive text-center" style={{fontWeight: 700, color: '#212121',}}> 
                          Preencha o formulário abaixo e receba o <br className='exc-mobile'/>contato de nossa equipe
                        </h2>
                        <p className="w-responsive text-center mx-auto mb-5 px-2" style={{fontWeight: 600, fontSize:'1rem',paddingLeft: '1rem', paddingRight: '1rem', color: '#ff6f01', marginTop: '2rem'}}>
                            Nossa equipe de sucesso cuidará de tudo para você!
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className='justify-content-center align-items-center'>  
                    <MDBCol md='5 ' className='mb-1'>
                      <div className='text-center'>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                                    <BsPersonFill style={{fontSize: '1.5rem', color: '#ff6f01'}}/>
                                  </span>
                                </div>
                                <input type="text" name="field_11" id="field_11" onChange={e => this.setState({nome: e.target.value})} placeholder='seu nome completo' style={{borderRadius: '0px 30px 30px 0px'}} className="form-control" />
                              </div> 
                              <br />
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                                    <MdAlternateEmail style={{fontSize: '1.5rem', color: '#ff6f01'}}/>
                                  </span>
                                </div>
                                <input type="email" name="field_12" id="field_12" onChange={e => this.setState({email: e.target.value})} placeholder='seu e-mail' style={{borderRadius: '0px 30px 30px 0px'}} className="form-control" />
                              </div>
                              <br />
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                                      <IoPhonePortraitSharp style={{fontSize: '1.5rem', color: '#ff6f01'}} /> 
                                  </span>
                                </div>
                                <input type="number" name="field_13" id="field_13" onChange={e => this.setState({telefone: e.target.value})} placeholder='seu telefone' style={{borderRadius: '0px 30px 30px 0px'}} className="form-control" />
                              </div>
                              <br />
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                                    <MdShareLocation style={{fontSize: '1.5rem', color: '#ff6f01'}}/>
                                  </span>
                                </div>
                                <input type="text" name="field_14" id="field_14" onChange={e => this.setState({cidade: e.target.value})} placeholder='sua cidade' style={{borderRadius: '0px 30px 30px 0px'}} className="form-control" />
                              </div>
                              <br />
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                                      <AiOutlineAlignLeft style={{fontSize: '1.5rem', color: '#ff6f01'}}/>
                                  </span>
                                </div>
                                <textarea type="text" name="field_15" id="field_15" row='4' onChange={e => this.setState({info: e.target.value})} placeholder='digite aqui informações que considere relevantes para nossa equipe' style={{borderRadius: '0px 30px 30px 0px'}} className="form-control" />
                              </div>
                              <div className="text-center mt-4">
                              <MDBBtn onClick={() => this.enviarContato()} style={{borderRadius: '15px', fontWeight: 600, width: '100%'}} className='btn-v1' disabled={(this.state.nome === '' || this.state.email === '' || this.state.telefone === '' || this.state.cidade === '' ? true : false)}>
                                <BiMessageSquareCheck style={{fontSize: '1.3rem'}}/>&nbsp; Solicitar contato
                              </MDBBtn>
                              <p className='' style={{marginTop: '2.5rem', fontWeight: '600', fontSize: '0.9rem'}}>
                                Preencha as informações acima, clique em <br />"Solicitar Contato" e pronto!
                              </p>
                              <p style={{fontSize: '0.6rem', fontWeight: 600}}>Ao clicar em "Solicitar Contato", você concorda com nossa <a href='/privacidade.pdf' target='_blank'>política de privacidade</a>.</p>
                              </div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </> 
                  : this.state.formstep === 'sending' ? 
                    <Loader />
                  : this.state.formstep === 'final' ? 
                  <MDBRow>
                    <MDBCol style={{paddingTop: '0.5rem',}}>
                      <div className='text-center'>
                        <div className='d-flex justify-content-center'>
                          <div className='icon-circle'>
                              <span>
                                <AiOutlineCheck style={{fontSize: '2rem', color: '#f5f5f5'}} />
                              </span>
                            </div>                
                        </div>                          
                        <h4 className="h1-responsive text-center" style={{fontWeight: 700, color: '#212121', marginTop: '2rem'}}> 
                          Recebemos sua solicitação!
                        </h4>
                        <p className="w-responsive text-center mx-auto mb-5 px-2" style={{fontWeight: 600, fontSize:'1rem',paddingLeft: '1rem', paddingRight: '1rem', color: '#ff6f01', marginTop: '2rem'}}>
                            Nossa equipe de sucesso entrará em contato com você!
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  : this.state.formstep === 'error' ? 
                  <MDBRow>
                    <MDBCol style={{paddingTop: '0.5rem',}}>
                      <div className='text-center'>
                        <div className='d-flex justify-content-center'>
                          <div className='icon-circle'>
                              <span>
                                <CgSandClock style={{fontSize: '2rem', color: '#f5f5f5'}} />
                              </span>
                            </div>                
                        </div>                          
                        <h4 className="h1-responsive text-center" style={{fontWeight: 700, color: '#212121', marginTop: '2rem', marginBottom: '2rem'}}> 
                          Estamos passando por uma manutenção!
                        </h4>
                        <span className='hover-shadow' onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=5514998186060'} style={{display: 'flex', justifyContent: 'center', backgroundColor: '#ff7300', borderRadius: '30px', paddingTop: '1rem', cursor: 'pointer'}}>
                          <span className='text-center' style={{backgroundColor: '#f5f5f5', borderRadius: '50%', width: '60px', height: '60px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                            <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <BsWhatsapp style={{color: '#ff6f00', fontSize: '1.5rem'}} />
                            </span>
                          </span> 
                          <span style={{fontSize: '1.5rem', paddingLeft: '1rem', fontWeight: 600, display: 'inline-block', color: '#f5f5f5'}}>
                            Fale com um corretor
                            <p style={{fontSize: '0.8rem', color: '#f5f5f5'}}>Clique aqui para iniciar uma conversa</p>
                          </span> 
                        </span>
                        <p className="w-responsive text-center mx-auto mb-5 px-2" style={{fontWeight: 600, fontSize:'1rem',paddingLeft: '1rem', paddingRight: '1rem', color: '#ff6f01', marginTop: '2rem'}}>
                            Nossa equipe de sucesso aguardo seu contato!
                        </p>

                      </div>
                    </MDBCol>
                  </MDBRow>
                  :
                    null
                }  
      </MDBContainer>
    </section>
    <Footer />
    </>
    );
  }
}

export default Vender;
