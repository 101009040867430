import { useState } from 'react';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBBtn } from 'mdb-react-ui-kit';
import { AiFillYoutube } from 'react-icons/ai';

const IframeModal = ({ url, toggleShow, modalActive }) => {
  const [basicModal, setBasicModal] = useState(modalActive);

  const handleCloseModal = () => {
    setBasicModal(false);
    toggleShow(false);
  };

  return (
    <MDBModal staticBackdrop show={modalActive} toggle={handleCloseModal} tabIndex='-1'>
      <MDBModalDialog>
        <MDBModalContent style={{ height: '90vh', }}>
          <MDBModalHeader toggle={handleCloseModal}>
            <MDBModalTitle><AiFillYoutube size={'1.5rem'} style={{color: '#ff7300'}}/>&nbsp; Apresentação do imóvel</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={handleCloseModal}></MDBBtn>
          </MDBModalHeader>
          <iframe
            width="100%"
            height="100%"
            src={url}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default IframeModal;
