import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBSwitch } from "mdb-react-ui-kit";
import { useState } from "react";
import Select from 'react-select'
import { GalleryUpdate } from "./gallery_update";
import { BsPencilSquare, BsCheck2Circle } from 'react-icons/bs';
import { BiErrorAlt } from 'react-icons/bi';
import { RegisterLoader } from "./preloader";
import { LoaderDash } from './loader';
import { MdVerified } from "react-icons/md";
import './components.css';

const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

function EditEmpreendimento({id}){

    const [select, setSelect] = useState(false)
    const [value, setValue] = useState('');
    const [updated, setUpdated] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);

    const update = async () => {

        if(!select && value.length <= 0 && id.length <= 0){
            return
        }

        setStatusLoading(true);     
        setUpdated(false);   

        let headersList = {
            mode: 'cors', 
            "Content-Type": "application/x-www-form-urlencoded"
        }

        let bodyContent = JSON.stringify({"id": id, "tipo": select.value, 
        "valor": value,});
      
        try{
          
          let response = await fetch(api + "updateimovel", { 
              method: "POST",
              body: bodyContent,                      
              headersList
          });

          let data = await response.json();

          if(data.status === true){
            setUpdated(true);
          }else{
            setUpdated('error');
          }

        }catch (e){
          //console.log(e);
          setUpdated('error');
        }
        
    }

    const retry = () => {
        setStatusLoading(false);     
        setUpdated(false);
    }

    const options = [        
        { value: 'nome', label: 'Nome do imóvel' },
        { value: 'rua', label: 'Rua do imóvel' },
        { value: 'complemento', label: 'Complemento do imóvel' },
        { value: 'bairro', label: 'Bairro do imóvel' },
        { value: 'cidade', label: 'Cidade do imóvel' },
        { value: 'descricao', label: 'Descrição do imóvel' },
        { value: 'sobre_localizacao', label: 'Sobre a localização do imóvel' },
        { value: 'legenda_valor', label: 'Legenda de destaque do imóvel' },
        { value: 'valor', label: 'Valor ou texto de destaque do imóvel' },
        { value: 'tipo', label: 'Tipo de imóvel' },
        { value: 'lazer', label: 'Lazer do imóvel' },
        { value: 'vista', label: 'Vista do imóvel' },
        { value: 'area_total', label: 'Área do imóvel' },
        { value: 'finalidade', label: 'Finalidade de negociação do imóvel' },
        { value: 'youtube_url', label: 'Link do YouTube' },
        { value: 'maps_url', label: 'Link do Google Maps' },
        { value: 'fotos', label: 'Fotos do imóvel' },          
      ]
      
    return (
        <>
        <MDBContainer>
            <MDBRow className="justify-content-center">
                <MDBCol md={11}>
                <div className="d-flex align-items-center"> 
                    <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                        <BsPencilSquare size={'2rem'} color='#ff7300'/>
                    </div>                               
                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                    <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #ff7300', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1.1rem'}}>
                        Editar imóvel
                    </span> <br />
                        Altere as informações relacionadas ao imóvel selecionado.</p>  
                </div>                    
                </MDBCol>
                <MDBCol md={11}>
                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-4'>Para começar, selecione o que você deseja alterar no imóvel:</label>
                    <Select className="mt-1" onChange={e => setSelect(e)} options={options} placeholder={'Selecione uma opção'} />
                </MDBCol>
                <MDBCol md={11}>
                    <hr />
                </MDBCol>                
                { select ?
                        <>
                            { select.value !== 'fotos' ?
                                <MDBCol md={11}>
                                    { !statusLoading ?
                                        <>
                                            <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem',}}>{select.label}</label>
                                            { select.value === 'tipo' ?
                                                <select id='type' onChange={e => setValue(e.target.value)} defaultValue={'null'} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}}>
                                                    <option value={'null'} disabled>Tipo</option> 
                                                    <option value={'Apartamento'}>Apartamento</option>
                                                    <option value={'Casa'}>Casa</option>
                                                    <option value={'Comercial'}>Comercial</option>
                                                    <option value={'Lazer'}>Lazer</option>
                                                    <option value={'Terreno'}>Terreno</option>
                                                    <option value={'Rural'}>Rural</option>
                                                </select>
                                            : select.value === 'finalidade' ?
                                                <select id='finality' onChange={e => setValue(e.target.value)} defaultValue={'null'} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}}>
                                                    <option value={'null'} disabled>Finalidade</option> 
                                                    <option value={'Locação'}>Alugar</option>
                                                    <option value={'Comprar'}>Comprar</option>
                                                </select>
                                            :
                                                <textarea defaultValue={value} onChange={e => setValue(e.target.value)} rows={2} placeholder="Digite aqui sua alteração para essa opção..." style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                                width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} />
                                            }
                                            <MDBBtn onClick={() => update()} style={{width:'100%' , marginTop: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem',}} outline>Salvar alteração</MDBBtn>
                                        </>
                                    :
                                        <>
                                        { updated === 'error' ?
                                            <MDBCol style={{paddingTop: '3rem'}} className='text-center'>
                                                <BiErrorAlt style={{width: '100px' , height: '100px', color: '#ff7300'}} />
                                                <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Houve um erro...</h4>
                                                <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                                                <i>Houve um erro ao registrar o imóvel em nossos bancos de dados, tente novamente.</i></p>
                                                <MDBBtn onClick={() => retry()} style={{width:'100%' , marginTop: '1.5rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem',}} outline>Tentar novamente</MDBBtn>
                                            </MDBCol>
                                          : updated ?
                                            <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                                                <BsCheck2Circle style={{width: '100px' , height: '100px', color: '#ff7300'}} />
                                                <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Tudo Pronto</h4>
                                                <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                                                    <i>O imóvel foi atualizado com sucesso.</i></p>
                                            </MDBCol> 
                                          :
                                            <RegisterLoader />
                                        }
                                        </>
                                    }
                                </MDBCol> 
                                :
                                <MDBCol md={12}>
                                    <GalleryUpdate id={id}/>
                                </MDBCol>
                            }
                        </>
                        :
                        null
                } 
            </MDBRow>
        </MDBContainer>
        </>
    );
}

function EditDestaque({id, auth, statusDestaque, updateDestaqueList}){

    const [select, setSelect] = useState(false)
    const [value, setValue] = useState('');
    const [updated, setUpdated] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const [ destaqueStatus, setDestaqueStatus ] = useState(statusDestaque);

    const update = async () => {

        if(!select && value.length <= 0 && id.length <= 0){
            return
        }

        setUpdated(false);   
        setStatusLoading(true);

        let headersList = {
            mode: 'cors', 
            "Content-Type": "application/x-www-form-urlencoded"
        }

        let bodyContent = JSON.stringify({"id": id, "tipo": select.value,"valor": value, "key": auth});
      
        try{
          
          let response = await fetch(api + "update/destaque/feature", { 
              method: "POST",
              body: bodyContent,                      
              headersList
          });

          let data = await response.json();

          if(data.status === true){
            setUpdated(true);
          }else{
            setUpdated('error');
          }

        }catch (e){
          //console.log(e);
          setUpdated('error');
        }
        
    }

    const updateStatusDestaque = async (id, status, key) => {

        setStatusLoading(true);     
        setUpdated(false);           
        setDestaqueStatus(false);


        let headersList = {
            mode: 'cors', 
            "Content-Type": "application/x-www-form-urlencoded"
        }

        let bodyContent = JSON.stringify({"id": id, "status": status, "key": key});
      
        try{
          
          let response = await fetch(api + "/update/destaque/status", { 
              method: "POST",
              body: bodyContent,                      
              headersList
          });

          let data = await response.json();

          if(data.status === true){
            setUpdated(true);
            setStatusLoading(false);
            setDestaqueStatus(status);
            updateDestaqueList();
          }else{
            setUpdated('error');
            setStatusLoading(false);
            setDestaqueStatus(!status);
          }

        }catch (e){
          //console.log(e);
          setUpdated('error');
        }
    }

    const retry = () => {
        setStatusLoading(false);     
        setUpdated(false);
    }

    const selectChange = (e) => {
        setSelect(e);
        setUpdated(false);
        setStatusLoading(false);
    }

    const options = [        
        { value: 'fotos', label: 'Foto de destaque' },
        { value: 'destaque_atr_1', label: 'Atributo 1' },
        { value: 'destaque_atr_2', label: 'Atributo 2' },     
      ]
      
    return (
        <>
        <MDBContainer>
            <MDBRow className="justify-content-center">
                <MDBCol md={12}>
                    <div className="d-flex align-items-center"> 
                        <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                            <MdVerified size={'2rem'} color='#ff7300'/>
                        </div>                               
                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                        <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #ff7300', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1.1rem'}}>
                            Habilitar destaque no imóvel
                        </span> <br />
                            Para comerçar você primeiro precisa habilitar este imóvel como um destaque.</p>  
                    </div>
                    <div className="d-flex justify-content-center" style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
                        <MDBSwitch id='destaqueSwitch' wrapperClass="custom-switch" defaultChecked={destaqueStatus} onChange={e => updateStatusDestaque(id, e.target.checked, auth)} labelStyle={{fontWeight: 600}} label={(destaqueStatus? 'Destaque habilitado' : 'Destaque desabilitado')} />
                    </div>
                </MDBCol>
                { destaqueStatus ?
                    <> 
                    <MDBCol md={12}>
                    <div>                              
                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginBottom: 0, paddingBottom: 0}}>
                        <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #ff7300', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1.1rem'}}>
                            Editar informações de destaque
                        </span> <br />
                            Altere as informações de destaque do imóvel selecionado.</p>  
                    </div>                    
                    </MDBCol>
                    <MDBCol md={12}>
                        <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-4'>Para começar, selecione o que você deseja alterar no imóvel:</label>
                        <Select className="mt-1" onChange={e => selectChange(e)} options={options} placeholder={'Selecione uma opção'} />
                    </MDBCol>
                    <MDBCol md={12}>
                        <hr />
                    </MDBCol>                               
                        { select ?
                            <>
                                    { select.value !== 'fotos' ?
                                        <MDBCol md={12}>
                                            { !statusLoading ?
                                            <>
                                                <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem',}}>{select.label}</label>                                        
                                                <textarea defaultValue={value} onChange={e => setValue(e.target.value)} rows={2} placeholder="Digite aqui sua alteração para essa opção..." style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} />
                                                <MDBBtn onClick={() => update()} style={{width:'100%' , marginTop: '1rem', borderRadius: '10px', textTransform: 'initial', fontWeight: 600, fontSize: '0.8rem', color: '#f5f5f5', backgroundColor: '#ff7300'}}>Salvar alteração</MDBBtn>                                                
                                            </>
                                            :
                                            <>
                                                { updated === 'error' ?
                                                    <MDBCol style={{paddingTop: '3rem'}} className='text-center'>
                                                        <BiErrorAlt style={{width: '100px' , height: '100px', color: '#ff7300'}} />
                                                        <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Houve um erro...</h4>
                                                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                                                        <i>Houve um erro ao registrar o imóvel em nossos bancos de dados, tente novamente.</i></p>
                                                        <MDBBtn onClick={() => retry()} style={{width:'100%' , marginTop: '1.5rem', borderRadius: '10px', textTransform: 'initial', fontWeight: 600, fontSize: '0.8rem',}} outline>Tentar novamente</MDBBtn>
                                                    </MDBCol>
                                                : updated ?
                                                    <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                                                        <BsCheck2Circle style={{width: '100px' , height: '100px', color: '#ff7300'}} />
                                                        <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Tudo Pronto</h4>
                                                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                                                            <i>O imóvel foi atualizado com sucesso.</i></p>
                                                    </MDBCol> 
                                                :
                                                    <RegisterLoader />
                                                }
                                            </>
                                            }
                                        </MDBCol> 
                                        :
                                        <MDBCol md={12}>
                                            <GalleryUpdate id={id} auth={auth} destaque/>                                            
                                        </MDBCol>                                        
                                    }
                            </>
                        :
                            null
                        }
                    </> 
                    :
                    null
                }

            </MDBRow>
        </MDBContainer>
        </>
    );
}

export { EditEmpreendimento, EditDestaque }