import { useState, useEffect }  from "react";
import { MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBRow, MDBContainer,  MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, } from 'mdb-react-ui-kit';
import { EmpreendimentosDashPreLoader, EmpreendimentosSimpleListPreloader, DeleteLoader } from "./preloader";
import { EditEmpreendimento, EditDestaque } from "./empreendimento-edit";
import { IoEyeOutline, IoFilterOutline } from 'react-icons/io5';
import { IoIosArrowBack } from 'react-icons/io';
import { BsPencilSquare, BsSearch } from 'react-icons/bs';
import { FaRegTrashAlt } from 'react-icons/fa';
import { TbTrashOff } from 'react-icons/tb';
import { MdVerified } from 'react-icons/md';
import '../index.css';

const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

function EmpreendimentoDashList ({authkey, updateDestaqueList}) {

    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [slug, setSlug] = useState('');
    const [destaqueStatus, setDestaqueStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [optionsStep, setOptionsStep] = useState('intro');
    const [basicModal, setBasicModal] = useState(false);
    const [ FiltrosArray, setFiltrosArray] = useState([ undefined, undefined, undefined, undefined, undefined ]);
    const [ resultSize, setResultSize ] = useState(null);
    const [ ImoveisData, setImoveisData ] = useState(false);
    const [ ImoveisStatus, setImoveisStatus ] = useState(false);
    const [ CidadesData, setCidadesData ] = useState(false);
    const [ LoaderShow, setLoaderShow ] = useState(true);


    const AtualizarFiltros = (indice, novoValor) => {
    setFiltrosArray(
      FiltrosArray.map((valor, i) => {
        if (i === parseInt(indice)) {
          return novoValor;
        } else {
          return valor;
        }
      })
    );
    }

    const cidadesCharger = () => {
        fetch(api + "cidades")
        .then((response) => response.json())
        .then((response) => {        
          setCidadesData(response);          
        })
        .catch((rejected) => {
          console.log(rejected);
        }); 
    }

    const refreshList = async (tipo, finalidade, cidade, classificar, slug) => {    
        setLoaderShow(true);
        if(cidade === undefined && tipo === undefined && finalidade === undefined && classificar === undefined){
            try{
        
                let headersList = {
                    mode: 'cors', 
                }
        
                let bodyContent = JSON.stringify({"authkey": authkey,});
    
                let response = await fetch(api + "platform/imoveis/all&all&all/", { 
                    method: "POST",
                    body: bodyContent,
                    headersList
                });
                
                let data = await response.json();
    
                setImoveisData(data.imoveis);                    
                setImoveisStatus(data.query.status);
                setResultSize(data.query.result_size);
                setLoaderShow(false);
    
            }catch (e){
                setImoveisData('error');
            }

        }else{

            let filtros = [];

            if(cidade === undefined || cidade === 'undefined') {
                filtros[0] = "all";
            }else{
                filtros[0] = cidade;
            }

            if(finalidade === undefined || finalidade === 'undefined') {
                filtros[1] = "all";
            }else{
                filtros[1] = finalidade;
            }

            if(tipo === undefined || tipo === 'undefined') {
                filtros[2] = "all";
            }else{
                filtros[2] = tipo;
            }

            if(classificar === undefined || classificar === 'undefined') {
                filtros[3] = '&undefined';
            }else{
                filtros[3] = '&'+classificar;
            }

            if(slug=== undefined || slug === 'undefined' || slug === '') {
                filtros[4] = false;
            }else{
                filtros[4] = slug;
            }
            
            try{
            
                let headersList = {
                    mode: 'cors', 
                }
        
                let bodyContent = JSON.stringify({"authkey": authkey,});

                let response = await fetch(api + "platform/imoveis/"+ filtros[0] + '&' + filtros[1] + '&' + filtros[2] + filtros[3] + (filtros[4] ? filtros[4] : ''), { 
                    method: "POST",
                    body: bodyContent,
                    headersList
                });
                
                let data = await response.json();

                setImoveisData(data.imoveis);
                setImoveisStatus(data.query.status);
                setResultSize(data.query.result_size);
                setLoaderShow(false);

            }catch (e){
                setImoveisData('error');
            }
        }
    }
    
    const toggleShow = () => {
        setBasicModal(!basicModal);
        refreshList(((FiltrosArray[2] === 'undefined' || FiltrosArray[2] === undefined ? 'all' : FiltrosArray[2])), ((FiltrosArray[1] === 'undefined' || FiltrosArray[1] === undefined ? 'all' : FiltrosArray[1])), ((FiltrosArray[0] === 'undefined' || FiltrosArray[0] === undefined ? 'all' : FiltrosArray[0])) , ((FiltrosArray[3] === 'undefined' || FiltrosArray[3] === undefined ? 'undefined' :  FiltrosArray[3])), ((FiltrosArray[4] === 'undefined' || FiltrosArray[4] === undefined ? 'undefined' :  '&' + FiltrosArray[4])))
        setOptionsStep('intro');
        if(deleteStatus === 'success'){
            setDeleteStatus(false);
            refreshList(((FiltrosArray[2] === 'undefined' || FiltrosArray[2] === undefined ? 'all' : FiltrosArray[2])), ((FiltrosArray[1] === 'undefined' || FiltrosArray[1] === undefined ? 'all' : FiltrosArray[1])), ((FiltrosArray[0] === 'undefined' || FiltrosArray[0] === undefined ? 'all' : FiltrosArray[0])) , ((FiltrosArray[3] === 'undefined' || FiltrosArray[3] === undefined ? 'undefined' :  FiltrosArray[3])), ((FiltrosArray[4] === 'undefined' || FiltrosArray[4] === undefined ? 'undefined' :  '&' + FiltrosArray[4])))
        }else{
            setDeleteStatus(false);
        }
    }

    const empreendimentoSelect = (id, nome, slug, destaque) => {
        setId(id);
        setNome(nome);
        setSlug(slug);
        setDestaqueStatus(destaque);
        toggleShow();
    }

    const filtrarImoveis = () => {
        refreshList(((FiltrosArray[2] === 'undefined' || FiltrosArray[2] === undefined ? 'all' : FiltrosArray[2])), ((FiltrosArray[1] === 'undefined' || FiltrosArray[1] === undefined ? 'all' : FiltrosArray[1])), ((FiltrosArray[0] === 'undefined' || FiltrosArray[0] === undefined ? 'all' : FiltrosArray[0])) , ((FiltrosArray[3] === 'undefined' || FiltrosArray[3] === undefined ? 'undefined' :  FiltrosArray[3])), ((FiltrosArray[4] === 'undefined' || FiltrosArray[4] === undefined ? 'undefined' :  '&' + FiltrosArray[4])))
    }

    const deleteEmpreendimento = async () => {

        setDeleteStatus('waiting');

        let headersList = {
            mode: 'cors', 
        }

        let bodyContent = JSON.stringify({"id": id,});

        try{
        
            let response = await fetch(api + "/deleteimovel", { 
                method: "POST",
                body: bodyContent,
                headersList
            });
            
            let data = await response.json();
            if(data.status){
                setDeleteStatus('success');
            }else{
                setDeleteStatus('error');
            }

        }catch (e){
            setDeleteStatus('error');
        }
    }

    useEffect(()=> {
        filtrarImoveis();
        cidadesCharger();
    },[]);

    
    return (
    <>
        <section style={{marginTop: '3rem',}}>
        <MDBContainer>
          <MDBRow className='exc-mobile'>
            <label className='mb-3' style={{fontWeight: 500, fontSize: '0.8rem',}}><i><span style={{fontWeight: 600,}}>Dica:</span> use nossos filtros para achar o imóvel que você procura!</i></label>
            <MDBCol md={10}>
              <div style={{border: '1px solid #d0d0d0', borderRadius: '30px', display: 'inline-block', padding: '0.3rem', width: '80%'}}>
                <MDBRow className='justify-content-center'>
                  <MDBCol size={3}>                    
                    <select id='type' onChange={e => AtualizarFiltros(2, e.target.value ) } onClick={e => AtualizarFiltros(2, e.target.value ) } defaultValue={FiltrosArray[2] && FiltrosArray[2] !== 'all' ? FiltrosArray[2] : 'all'} className="form-control select-ios text-center select" style={{border: 'none', fontSize: '0.8rem', display: 'inline', borderRadius: '30px', fontWeight: 500}}>
                      <option value={'null'} disabled>Tipo</option> 
                      <option value={'all'}>Todos os tipos</option>
                      <option value={'Apartamento'}>Apartamento</option>
                      <option value={'Casa'}>Casa</option>
                      <option value={'Comercial'}>Comercial</option>
                      <option value={'Lazer'}>Lazer</option>
                      <option value={'Terreno'}>Terreno</option>
                      <option value={'Rural'}>Rural</option>
                    </select>
                  </MDBCol>  
                  <MDBCol size={3}>                    
                    <select id='finality' onChange={e => AtualizarFiltros(1, e.target.value ) } onClick={e => AtualizarFiltros(1, e.target.value ) } defaultValue={FiltrosArray[1] && FiltrosArray[1] !== 'all' ? FiltrosArray[1] : 'all'} className="form-control select-ios text-center select" style={{border: 'none', fontSize: '0.8rem', display: 'inline', borderRadius: '30px', fontWeight: 500}}>
                      <option value={'null'} disabled>Finalidade</option> 
                      <option value={'all'}>Todas as finalidades</option>
                      <option value={'Locação'}>Alugar</option>
                      <option value={'Comprar'}>Comprar</option>
                    </select>
                  </MDBCol> 
                  <MDBCol size={3}>
                    <select id='location' onChange={e => AtualizarFiltros(0, e.target.value ) } onClick={e => AtualizarFiltros(0, e.target.value ) } defaultValue={FiltrosArray[0] && FiltrosArray[0] !== 'all' ? FiltrosArray[0] : 'all'} className="form-control select-ios text-center select" style={{border: 'none', fontSize: '0.8rem', display: 'inline', borderRadius: '30px', fontWeight: 500}}>
                      <option value={'null'} disabled>Escolha a cidade</option>
                      <option value={'all'}>Todas as cidades</option>
                      {CidadesData.result &&  CidadesData.result.map((CIDADE) => <option key={CIDADE.CIDADE} value={CIDADE.CIDADE}>{CIDADE.CIDADE}</option>)}
                    </select>
                  </MDBCol>  
                  <MDBCol size={3}>
                    <input id='slug' type="text" placeholder="Slug do imóvel" onChange={e => AtualizarFiltros(4, e.target.value ) } defaultValue={FiltrosArray[4]} className="form-control select-ios text-center" style={{border: 'none', fontSize: '0.8rem', display: 'inline', borderRadius: '30px', fontWeight: 500}}>
                    </input>
                  </MDBCol>
                </MDBRow>
              </div>
              <button onClick={() => filtrarImoveis()} style={{backgroundColor: '#65005a', borderRadius: '50%', color: '#fff', border: 'none', width: '37px', height: '37px', marginLeft: '1rem'}}>
                <BsSearch style={{color: '#fff', fontSize: '1.1rem'}}/>
              </button>
            </MDBCol>
            <MDBCol md={2}>
              <div style={{border: '1px solid #d0d0d0', borderRadius: '30px', float: 'right', display: 'inline-block', padding: '0.3rem',  width: '100%'}}>
                <select id='location' onChange={e => AtualizarFiltros(3, e.target.value )} onClick={e => AtualizarFiltros(3, e.target.value )} defaultValue={FiltrosArray[3] && FiltrosArray[3] !== 'all' ? FiltrosArray[3] : 'null'} className="form-control select-ios text-center select" style={{border: 'none', fontSize: '0.8rem', display: 'inline', borderRadius: '30px', fontWeight: 500, width: '100%'}}>
                  <option value='null' disabled>Classificar por</option>
                  <option value={'recentes'}>Recentes</option>
                  <option value={'antigos'}>Antigos</option>
                  <option value={'preco-menor'}>Preço menor</option>
                  <option value={'preco-maior'}>Preço maior</option>
                </select>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <MDBContainer>
          <div className='exc-desktop text-center'>
            <button onClick={() => setExibirSideBar(!exibirSideBar)} style={{ backgroundColor: '#ff7300', borderRadius: '30px',border: 'none', color: '#f5f5f5', padding: '0.5rem 1rem', fontWeight: 600 }} ><IoFilterOutline style={{marginRight: '0.5rem', fontSize: '1.3rem'}}/> Filtrar minha busca</button>
          </div>  
          <div className='gradient title-gradient' style={{height: '2rem', marginTop: '2rem', marginBottom: '1.2rem', display:  LoaderShow ? 'block' : 'none'}}></div>
            <p style={{fontWeight: 500, fontSize: '0.9rem', display: LoaderShow ? 'none' : 'block', marginBottom: '2rem', marginTop: '2rem'}}> 
                <i>{resultSize > 1 ? 'Encontramos ' + resultSize + ' imóveis cadastrados' : resultSize == 1 ?  'Achamos este imóvel cadastrado' : 'Ops, parece que não encontramos nenhum imóvel.'}</i>
            </p>        
        </MDBContainer>     
      </section>
        { ImoveisData ?
            <MDBContainer>
                <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                    <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                        <MDBModalTitle style={{paddingLeft: '0.7rem', borderRadius: '4px', borderLeft: 'solid 0.4rem #ff7300',}}>{nome}</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBContainer>
                            { optionsStep === 'intro' ?
                                <MDBRow className="justify-content-center" style={{paddingBottom: '1.6rem'}}>
                                    <MDBCol md={12}>
                                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem'}} className='text-center'>Selecione uma das opções para continuar</p>
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        <MDBCard onClick={() => window.open('/buscar/imovel/'+slug, '_blank')} className="hover-shadow" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px',}}>
                                            <MDBCardBody>
                                                <div className="d-flex align-items-center"> 
                                                    <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                                                        <IoEyeOutline size={'2rem'} color='#ff7300'/>
                                                    </div>                               
                                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                        <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #ff7300', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                                            Visualizar Online
                                                        </span> <br />
                                                        Clique para visualizar a página deste imóvel.</p>  
                                                </div> 
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        <MDBCard onClick={() => setOptionsStep('editar')} className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px',}}>
                                            <MDBCardBody>
                                                <div className="d-flex align-items-center"> 
                                                    <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                                                        <BsPencilSquare size={'2rem'} color='#ff7300'/>
                                                    </div>                               
                                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                        <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #ff7300', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                                            Editar Imóvel
                                                        </span> <br />
                                                        Clique para acessar as opções para esse imóvel.</p>  
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        <MDBCard onClick={() => setOptionsStep('destaque')} className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px',}}>
                                            <MDBCardBody>
                                                <div className="d-flex align-items-center"> 
                                                    <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                                                        <MdVerified size={'2rem'} color='#ff7300'/>
                                                    </div>                               
                                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                        <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #ff7300', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                                            Destacar Imóvel
                                                        </span> <br />
                                                        Clique para acessar as opções de destaque para esse imóvel.</p>  
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        <MDBCard onClick={() => setOptionsStep('deletar')} className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px',}}>
                                            <MDBCardBody>
                                                <div className="d-flex align-items-center"> 
                                                    <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                                                        <FaRegTrashAlt size={'2rem'} color='#ff7300'/>
                                                    </div>                               
                                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                        <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #ff7300', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                                            Excluir Imóvel
                                                        </span> <br />
                                                        Clique para acessar as opções de exclusão para esse imóvel.</p>  
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                            : optionsStep === 'editar' ?
                                <>
                                    <EditEmpreendimento id={id}/>
                                    <MDBRow className="text-center">
                                        <MDBCol>
                                            <MDBBtn onClick={() => setOptionsStep('intro')} color="danger" style={{width:'86.5%' , marginTop: '1rem', marginBottom: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem',}} outline>
                                                Fechar
                                            </MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </>

                            : optionsStep === 'destaque' ?
                            <>
                                <EditDestaque id={id} auth={authkey} updateDestaqueList={updateDestaqueList} statusDestaque={(destaqueStatus === 'Ativado' ? true : false)}/>
                                <MDBRow className="text-center">
                                    <MDBCol>
                                        <MDBBtn onClick={() => setOptionsStep('intro')} color="danger" style={{width:'86.5%' , marginTop: '1rem', marginBottom: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem',}} outline>
                                            Fechar
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </>
                            : optionsStep === 'deletar' ?
                                <MDBRow>
                                    { deleteStatus === false ?
                                    <>
                                        <MDBCol md={12}>
                                            <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem'}} className='text-center'>Continuar com exclusão do imóvel?</p>
                                        </MDBCol>
                                        <MDBCol md={12}>
                                            <MDBCard onClick={() => deleteEmpreendimento()} className="hover-shadow mt-1" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px',}}>
                                                <MDBCardBody>
                                                    <div className="d-flex align-items-center"> 
                                                        <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                                                            <FaRegTrashAlt size={'2rem'} color='#ff7300'/>
                                                        </div>                               
                                                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                            <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #ff7300', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                                                Continuar com exclusão
                                                            </span></p>  
                                                    </div>
                                                </MDBCardBody>
                                            </MDBCard>
                                            <MDBCard onClick={() => setOptionsStep('intro')} className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px', padding: '1rem'}}>
                                                    <div>                               
                                                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.7rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                            <span style={{ fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '0.8rem'}}>
                                                            <IoIosArrowBack size={'1.7rem'} color='#ff7300'/> Voltar para opções
                                                            </span></p>  
                                                    </div>
                                            </MDBCard>
                                        </MDBCol>
                                    </>
                                    : deleteStatus === 'waiting' ?
                                        <DeleteLoader />
                                    : deleteStatus === 'success' ?
                                        <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                                            <FaRegTrashAlt style={{width: '100px' , height: '100px', color: '#ff7300'}} />
                                            <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Tudo Pronto</h4>
                                            <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                                                <i>O imóvel foi excluído com sucesso.</i></p>
                                        </MDBCol> 
                                    : deleteStatus === 'error' ?
                                        <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                                            <TbTrashOff style={{width: '100px' , height: '100px', color: '#ff7300'}} />
                                            <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Houve um erro</h4>
                                            <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                                                <i>Não foi possível excluir o imóvel. Tente novamente.</i></p>
                                        </MDBCol> 
                                    :
                                        null       
                                    }
                                </MDBRow>
                            :
                                null
                            }
                            </MDBContainer>
                        </MDBModalBody>
                    </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                <MDBRow style={{marginBottom: '3rem'}}>
                {ImoveisData.map( (imoveis) => 
                    <MDBCol md={6} key={imoveis.ID}>
                        <MDBCard onClick={() => empreendimentoSelect(imoveis.ID, imoveis.NOME, imoveis.SLUG, imoveis.DESTAQUE)} className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '15px', minHeight: '300px', backgroundPosition: 'top center', backgroundSize: 'cover', backgroundImage: 'url("'+api+"images/"+imoveis.ID+'/'+imoveis.FOTOS.split(";")[0]+'")'}}>
                            <MDBCardBody style={{backgroundColor: '#fff', maxHeight: '180px', borderRadius: '15px',}}>
                                <p style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #ff7300', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                    {imoveis.NOME}</p>
                                <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem'}}>{imoveis.CIDADE}</p>
                                <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginBottom: 0}}>
                                    Clique para acessar as opções para esse imóvel.</p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>  
                )}
                </MDBRow>
            </MDBContainer>
            : LoaderShow ? 
            <MDBContainer style={{paddingTop: '1rem'}}>
                <EmpreendimentosDashPreLoader />
            </MDBContainer>
            :
            null
        }
    </>
    );
}

function EmpreendimentoDestaqueList ({ authkey, counter, updateDestaqueList }) {

    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [basicModal, setBasicModal] = useState(false);
    const [ ImoveisData, setImoveisData ] = useState(false);
    const [ LoaderShow, setLoaderShow ] = useState(true);
    const [ destaqueStatus, setDestaqueStatus ] = useState(false);
    const [ Slug, setSlug ] = useState(false);


    const refreshList = async () => {    
        setLoaderShow(true);
        try{
        
            let headersList = {
                mode: 'cors', 
            }
    

            let response = await fetch(api + "imoveis/destaques", { 
                method: "GET",
                headersList
            });
            
            let data = await response.json();

            setImoveisData(data.destaques);                                
            setLoaderShow(false);

        }catch (e){
            setDeleteStatus('error');
        }
    }

    const empreendimentoSelect = (id, nome, slug, destaque) => {
        setId(id);
        setNome(nome);
        setSlug(slug);
        setDestaqueStatus(destaque);
        setBasicModal(true);
    }

    useEffect(() => {
        refreshList();
    },[]);

    useEffect(() => {
        refreshList();
        setBasicModal(false);
      }, [counter]);


    return (
    <>
        { ImoveisData ?
            <MDBContainer>
                <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                    <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                        <MDBModalTitle style={{paddingLeft: '0.7rem', borderRadius: '4px', borderLeft: 'solid 0.4rem #ff7300',}}>{nome}</MDBModalTitle>
                        <MDBBtn className='btn-close' onClick={() => setBasicModal(false)} color='none'></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBContainer>
                                <MDBRow>
                                    <EditDestaque id={id} auth={authkey} updateDestaqueList={updateDestaqueList} statusDestaque={(true)}/>
                                    <MDBCol className="text-center">
                                        <MDBBtn onClick={() => setBasicModal(false)} color="danger" style={{width:'86.5%' , marginTop: '1rem', marginBottom: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem',}} outline>
                                            Fechar
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBModalBody>
                    </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                <MDBRow style={{marginBottom: '3rem'}}>
                {ImoveisData.map( (imoveis) => 
                    <MDBCol md={12} key={imoveis.ID}>
                        <MDBCard className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '15px', backgroundPosition: 'top center', backgroundSize: 'cover',}} onClick={() => empreendimentoSelect(imoveis.ID, imoveis.NOME, imoveis.SLUG, imoveis.DESTAQUE)}>
                            <MDBCardBody style={{backgroundColor: '#fff', maxHeight: '180px', borderRadius: '15px',}}>
                                <MDBRow>
                                    <MDBCol size={4} className="align-content-center d-flex">
                                        <img src={api+"images/"+imoveis.ID+'/'+imoveis.DESTAQUE_IMG.split(";")[0]} alt='imagem de destaque' style={{borderRadius: '15px'}} className="img-fluid"/>
                                    </MDBCol>
                                    <MDBCol size={8}>
                                        <p style={{fontWeight: 600, fontSize: '0.8rem'}}>{imoveis.NOME}</p>
                                        <p style={{fontWeight: 600, fontSize: '0.7rem', marginBottom: 0}}>
                                            Clique para acessar as opções para esse imóvel.</p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>  
                )}
                </MDBRow>
            </MDBContainer>
            : LoaderShow ?
            <MDBContainer style={{paddingTop: '0.5rem'}}>
                <EmpreendimentosSimpleListPreloader />
            </MDBContainer>
            :
            <p className="text-center">Nenhum imóvel em destaque</p>
        }
    </>
    );
}

export { EmpreendimentoDashList, EmpreendimentoDestaqueList };