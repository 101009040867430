import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { MDBContainer,MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdb-react-ui-kit";
import { RegisterLoader } from "./preloader";
import { GalleryUpdate } from "./gallery_update";
import '../index.css';
import { GoVerified } from 'react-icons/go';
import { AiOutlinePicLeft } from 'react-icons/ai';
import { BsChevronRight, BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import { BiErrorAlt } from 'react-icons/bi';
import { CgTrees } from 'react-icons/cg';
import { GiCrane } from 'react-icons/gi';
import { FaTheaterMasks } from 'react-icons/fa';
import { MdOutlineShareLocation, MdOutlineSupportAgent, MdOtherHouses, MdLocalPolice, MdPhoneCallback } from 'react-icons/md';
import { IoLogoWhatsapp } from 'react-icons/io';
import { IoImage } from 'react-icons/io5';
import { SiGooglestreetview } from 'react-icons/si';


function EmpreendimentoRegister () {

    const [step, setStep] = useState('introducao');
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [rua, setRua] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [legenda, setLegenda] = useState('');
    const [valorLegenda, setValorLegenda] = useState('');
    const [descricao, setDescricao] = useState('');
    const [sobreLocalizacao, setSobreLocalizacao] = useState('');
    const [tipo, setTipo] = useState('null');
    const [finalidade, setFinalidade] = useState('null');
    const [area, setArea] = useState('');
    const [vista, setVista] = useState('');
    const [lazer, setLazer] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [mapsLink, setMapsLink] = useState('');

    const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");
    
    const register = async () => {

        setStep('send-api');

        let headersList = {
            mode: 'cors', 
        }
        
        let bodyContent = JSON.stringify({ "nome": nome, "rua" : rua, "complemento" : complemento, "bairro" : bairro, 
        "cidade": cidade,  "descricao": descricao, "sobre_localizacao" : sobreLocalizacao, "legenda_valor": legenda,
        "tipo" : tipo, "valor" : valorLegenda, "area_total" : area, "lazer" : lazer, "vista" : vista, "finalidade" : finalidade, 
        "youtube_url" : youtubeLink, "maps_url" : mapsLink });

        try {
            let response = await fetch(api + "createimovel", { 
                method: "POST",
                body: bodyContent,
                headersList
            });
            
            let data = await response.json();
            
            if(data.status){
                setId(data.id_imovel);
                setStep('fotos');
            }else{
                setStep('error');
            }       
        }catch(e){
            setStep('error');
        }    
    }

   useEffect(()=> {
        document.title = 'Cadastrar Imóveis | Plataforma de Gerenciamento Imóvel Valoriza';
    },[]);



    return(
        <>
                <MDBContainer>                
                    <MDBRow className="justify-content-center" style={{paddingBottom: '5rem'}}>
                        <MDBCol md={10} className='text-center'>
                            <h4 style={{borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '3rem', fontWeight: 600}}>Cadastro de imóvel</h4>
                            <p style={{fontSize: '0.9rem', fontWeight: 600}}>Vamos lá? Basta seguir as instruções para fazer o cadastro do novo imóvel.</p>
                        </MDBCol>                    
                    {step === 'introducao' ?
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Primeiro vamos identificar o imóvel</h6>
                                    <p style={{ fontSize: '0.9rem', fontWeight: 600}}>Informe abaixo o nome, cidade e estado do imóvel.</p>
                                    
                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Digite o nome do imóvel <b>*</b></label>
                                    <input placeholder="Nome do imóvel" defaultValue={nome} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setNome(e.target.value)}/>

                                    <label className="mt-2" style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o endereço do imóvel?<b>*</b></label>
                                    <input placeholder='Exemplo: Rua X, 00' defaultValue={rua} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setRua(e.target.value)}/> 

                                    <label className="mt-2" style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o complemento do imóvel?</label>
                                    <input placeholder='Exemplo: Casa 02' defaultValue={complemento} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setComplemento(e.target.value)}/>

                                    <label className="mt-2" style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o bairro do imóvel?<b>*</b></label>
                                    <input placeholder='Exemplo: "Boa Vista"' defaultValue={bairro} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setBairro(e.target.value)}/>

  
                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-2'>Digite a cidade e estado do imóvel <b>*</b></label>
                                    <input placeholder="Cidade" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={cidade} onChange={e => setCidade(e.target.value)}/>

                                    <label className="mt-2" style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual a legenda de destaque do imóvel?<b>*</b></label>
                                    <input placeholder='Exemplo: "Parcelas a partir de" ou "A vista"' defaultValue={legenda} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setLegenda(e.target.value)}/>

                                    <label className="mt-2" style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o destaque do imóvel? <b>*</b></label>
                                    <input placeholder='Exemplo: "Sob consulta" ou "R$ 1.500,00"' defaultValue={valorLegenda} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setValorLegenda(e.target.value)}/>

                                    { nome.length > 0 && rua.length > 0 && bairro.length > 0 && cidade.length > 0 && legenda.length > 0 && valorLegenda.length > 0 ?
                                        <MDBBtn onClick={() => setStep('vantagens')} className="mt-4" color="light" style={{fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039'}} outline>Continuar <BsArrowRight /></MDBBtn>
                                        :
                                        <p style={{marginTop: '1rem', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.3rem', marginBottom: '-0.7rem', float: 'right'}}>Preencha os campos com "*" para continuar.</p>
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>  
                    : step === 'vantagens' ?    
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Legal, agora nos de alguns detalhes do imóvel</h6>
                                    <p style={{ fontSize: '0.9rem', fontWeight: 600}}>Informe abaixo a descrição (introdução) do imóvel e 
                                    recursos relacionados a infraestrutura, segurança, lazer e sustentabilidade.</p>
                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Digite a descrição (introdução) do imóvel: <b>*</b></label>
                                    <textarea rows={3} placeholder="Apresente o imóvel para seu cliente..." defaultValue={descricao} className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setDescricao(e.target.value)}/>
                                    
                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Agora fale sobre a localização do imóvel: <b>*</b></label>
                                    <textarea rows={3} placeholder="Apresente o imóvel para seu cliente..." defaultValue={sobreLocalizacao} className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setSobreLocalizacao(e.target.value)}/>
                                    
                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Informe o total do imóvel em m²: <b>*</b></label>
                                    <textarea rows={2} placeholder="Exemplo: 120" className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={area} onChange={e => setArea(e.target.value)}/>
                                    
                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Quais recursos de vista o imóvel tem?</label>
                                    <textarea rows={2} placeholder="Exemplo: Portaria 24 horas, próximo a base da polícia etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={vista} onChange={e => setVista(e.target.value)}/>
                                    
                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Quais recursos de lazer o imóvel tem?</label>
                                    <textarea rows={2} placeholder="Exemplo: Próximo a parques, Próximo aos principais pontos de lazer da cidade, salão de festas e etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={lazer} onChange={e => setLazer(e.target.value)}/>
                                                                        
                                    <p style={{fontWeight: 600, fontSize: '0.9rem', color: '#D38039', marginTop: '0.5rem'}}>Atenção: campos deixados em branco não serão exibidos na página do imóvel.</p>

                                    <MDBBtn className="mt-2" color="light" style={{fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039'}} outline onClick={() => setStep('introducao')}><BsArrowLeft /> Introdução</MDBBtn>
                                    { descricao.length > 0 && area.length > 0 && sobreLocalizacao.length > 0?
                                        <MDBBtn onClick={() => setStep('outros-detalhes')} className="mt-2" color="light" style={{fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039', float: 'right'}} outline>Continuar <BsArrowRight /></MDBBtn>
                                        :
                                        <p style={{marginTop: '1rem', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.3rem', marginBottom: '-0.7rem', float: 'right'}}>Preencha os campos com "*" para continuar.</p>
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>     
                    : step === 'outros-detalhes' ?    
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Estamos quase lá...</h6>
                                    <p style={{ fontSize: '0.9rem', fontWeight: 600}}>Informe abaixo o número de lotes totais e vendidos.</p>
                                    
                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Agora fale sobre a localização do imóvel: <b>*</b></label>
                                    <select id='type' onChange={e => setTipo(e.target.value)} defaultValue={tipo} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}}>
                                    <option value={'null'} disabled>Tipo</option> 
                                    <option value={'Apartamento'}>Apartamento</option>
                                    <option value={'Casa'}>Casa</option>
                                    <option value={'Comercial'}>Comercial</option>
                                    <option value={'Lazer'}>Lazer</option>
                                    <option value={'Terreno'}>Terreno</option>
                                    <option value={'Rural'}>Rural</option>
                                    </select>

                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Agora fale sobre a localização do imóvel: <b>*</b></label>
                                    <select id='finality' onChange={e => setFinalidade(e.target.value)} defaultValue={finalidade} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}}>
                                    <option value={'null'} disabled>Finalidade</option> 
                                    <option value={'Locação'}>Alugar</option>
                                    <option value={'Comprar'}>Comprar</option>
                                    </select>

                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-2'>Link para YouTube embed</label>
                                    <input placeholder="Exemplo: https://www.youtube-nocookie.com/embed/IOs8t6hN4To" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={youtubeLink} onChange={e => setYoutubeLink(e.target.value)}/>       
                                    
                                    <label style={{fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-2'>Link para o Google Maps</label>
                                    <input placeholder="Exemplo: " style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={mapsLink} onChange={e => setMapsLink(e.target.value)}/>                     
                                    
                                    <p style={{fontWeight: 600, fontSize: '0.9rem', color: '#D38039', marginTop: '0.5rem'}}>Atenção: campos deixados em branco não serão exibidos na página do imóvel.</p>
                                    <MDBBtn className="mt-2" color="light" style={{fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039'}} outline onClick={() => setStep('vantagens')}><BsArrowLeft /> Vantagens</MDBBtn>
                                    { finalidade !== 'null' && tipo !== 'null' ?
                                        <MDBBtn onClick={() => register()} className="mt-2" color="light" style={{fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039', float: 'right'}} outline>Continuar <BsArrowRight /></MDBBtn>
                                        :
                                        <p style={{marginTop: '1rem', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.3rem', marginBottom: '-0.7rem', float: 'right'}}>Preencha os campos com "*" para continuar.</p>
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>  
                    : step === 'send-api' ?
                        <MDBCol md={10}>
                            <RegisterLoader />
                        </MDBCol>  
                    : step === 'fotos' ?
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Agora falta apenas um passo...</h6>
                                    <p style={{ fontSize: '0.9rem', fontWeight: 600}}>Para que seu imóvel fique visível, envie abaixo as fotos relacionadas a ele.</p>
                                    <GalleryUpdate id={id} />
                                    <div className="text-center mt-4">
                                        <a href="/platform/gerenciar">Continuar mais tarde...</a>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>                            
                        </MDBCol>  
                    : step === 'error' ?                    
                        <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                            <BiErrorAlt style={{width: '100px' , height: '100px', color: '#D38039'}} />
                            <h4 style={{marginTop: '2rem', fontWeight: 600,}}>Houve um erro...</h4>
                            <p style={{marginTop: '1rem', fontWeight: 600, fontSize: '0.85rem'}}>
                            <i>Houve um erro ao registrar o imóvel em nossos bancos de dados, tente novamente.</i></p>
                            <MDBBtn onClick={() => setStep('outros-detalhes')} style={{fontWeight: 600, fontSize: '0.9rem', borderRadius: '20px', backgroundColor: '#D38039'}}>Tentar novamente</MDBBtn>
                        </MDBCol> 
                    :
                        null                        
                    }                                 
                    </MDBRow>
                </MDBContainer>
        </>
    );
}

export default EmpreendimentoRegister;