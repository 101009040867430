import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { AiOutlineFileSearch } from 'react-icons/ai';
import './components.css';
import CoinIcon from '../img/coin.png';
import DocumentIcon from '../img/document.png';
import ReadDocumentIcon from '../img/read-document.png';


function Beneficios(){
    return (
        <>
            <MDBRow className='justify-content-center' style={{marginTop: '2rem'}}>
                <MDBCol md={3} className='text-center justify-content-center mt-3'>
                    <div className='d-flex justify-content-center'>
                        <div style={{borderRadius: '50%', backgroundColor: '#ff7300', width: '65px', height: '65px'}} 
                            className='text-center d-flex justify-content-center align-items-center'>
                            <span><img src={ReadDocumentIcon} width='40px' height='40px' /></span>
                        </div>
                    </div>
                    <h5 style={{fontWeight: 700, marginTop: '1.2rem', padding: '0 5rem 0 5rem'}}>Encontre e visite</h5>
                    <p style={{fontWeight: 500, fontSize: '0.9rem', marginTop: '1rem', padding: '0 1.5rem 0 1.5rem'}}>
                        Ao encontrar a melhor opção, te acompanharemos para garantir uma visita eficiente.</p>
                </MDBCol>
                <MDBCol md={3} className='text-center justify-content-center mt-3'>
                    <div className='d-flex justify-content-center'>
                        <div style={{borderRadius: '50%', backgroundColor: '#ff7300', width: '65px', height: '65px'}} 
                            className='text-center d-flex justify-content-center align-items-center'>
                            <span><img src={CoinIcon} width='40px' height='40px' /></span>
                        </div>
                    </div>
                    <h5 style={{fontWeight: 700, marginTop: '1.2rem', padding: '0 2.5rem 0 2.5rem'}}>Decida como pagar</h5>
                    <p style={{fontWeight: 500, fontSize: '0.9rem', marginTop: '1rem', padding: '0 1.5rem 0 1.5rem'}}>
                        Fazemos toda a negociação e oferecemos assessoria financeira para o processo de financiamento.</p>
                </MDBCol>
                <MDBCol md={3} className='text-center justify-content-center mt-3'>
                    <div className='d-flex justify-content-center'>
                        <div style={{borderRadius: '50%', backgroundColor: '#ff7300', width: '65px', height: '65px'}} 
                            className='text-center d-flex justify-content-center align-items-center'>
                            <span><img src={DocumentIcon} width='40px' height='40px' /></span>
                        </div>
                    </div>
                    <h5 style={{fontWeight: 700, marginTop: '1.2rem', padding: '0 1rem 0 1rem'}}>Pronto!<br />É só assinar.</h5>
                    <p style={{fontWeight: 500, fontSize: '0.9rem', marginTop: '1rem', padding: '0 1.6rem 0 1.6rem'}}>
                        Nosso time é especialista e dará toda a assessoria para uma compra rápida e segura.</p>
                </MDBCol>
            </MDBRow>
        </>
    );
}

export { Beneficios };