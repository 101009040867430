import { useState } from "react";
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarToggler, MDBIcon, MDBCollapse, MDBNavbarNav, MDBNavbarItem, MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';
import { MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, } from 'mdb-react-ui-kit';
import { Si1Password } from 'react-icons/si';
import { MdVerified } from 'react-icons/md';
import { BiMessageAltError } from 'react-icons/bi';
import { LoaderDash } from "./loader";
import { changePassword } from "./changePasword";
import LogoImg from '../img/logo.svg';
import Cookies from 'js-cookie';

function DashboardNavbar({username, authkey}) {

  const removeAuth = () => {
    Cookies.remove('id_adm');
    window.location.href='/login';
  }

  const [ showNavRight, setShowNavRight ] = useState(false);  
  const [ passModal, setPassModal ] = useState(false);
  const [ stepPass, setStepPass ] = useState('collect-step');
  const [ currentPassword, setCurrentPassword ] = useState('');
  const [ newPassword, setNewPassoword ] = useState('');
  const [ re_NewPassword, setRe_NewPassword ] = useState('');
  const [ error, setError ] = useState('');

  const toggleShow = () => {
    setPassModal(!passModal);
    setStepPass('collect-step');
  }

  const changePass = async () => {
    if (re_NewPassword === newPassword && newPassword.trim() !== '') {      
      setStepPass('waiting-response-step');
      try {
        const response = await changePassword(authkey, currentPassword, newPassword);
        if (response) {
          setStepPass('success-step');
        } else {
          setStepPass('error-step');          
        }
      } catch (error) {
        setStepPass('error-step');
        console.log(error);
      }
    } else {
      setError('As senhas não são iguais.')
    }
  }
  
  
  
  return (
    <>
    <MDBNavbar expand='lg' sticky style={{backgroundColor: '#fbfbfb', borderBottom: 'solid 1px #dedede'}} className="shadow-0">
      <MDBContainer style={{maxWidth: '94%'}}>
        <MDBNavbarBrand href='#'>
            <img src={LogoImg} height='35' alt='Logo JT' loading='lazy'/>
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type='button'
          data-target='#navbarRightAlignExample'
          aria-controls='navbarRightAlignExample'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNavRight(!showNavRight)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNavRight}>
          <MDBNavbarNav fullWidth={false} className='mx-auto'>
            <MDBNavbarItem>
              <a href='/platform'>
                <span style={{fontWeight: 600, marginRight: '1rem', color: '#151515'}}>
                  &nbsp; Início
                </span>
              </a>
            </MDBNavbarItem>  
            <MDBNavbarItem>
              <a href='/platform/adicionar'>
                <span style={{fontWeight: 600, marginRight: '1rem', color: '#151515'}}>
                  &nbsp; Novo Imóvel
                </span>
              </a>
            </MDBNavbarItem>  
            <MDBNavbarItem>
              <a href='/platform/gerenciar' style={{border: 'none'}}>
                <span style={{fontWeight: 600, marginRight: '1rem', color: '#151515'}}>
                  &nbsp; Gerenciar Imóveis
                </span>
              </a>
            </MDBNavbarItem>           
          </MDBNavbarNav>
          <MDBDropdown style={{fontWeight: 600, marginRight: '1rem', color: '#151515', display: 'inline-block'}}>
            <MDBDropdownToggle tag='a' className='btn btn-primary shadow-0' style={{textTransform: 'initial', fontSize: '1rem', backgroundColor: "transparent", color: '#151515'}}>
              {username}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem  onClick={toggleShow} aria-disabled={true} link>Alterar Senha</MDBDropdownItem>
              <MDBDropdownItem onClick={() => removeAuth()} link>Desconectar</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBCollapse>
      </MDBContainer>      
    </MDBNavbar>

      <MDBModal show={passModal} setShow={setPassModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle><Si1Password size={'1.5rem'} style={{marginRight: '0.7rem', color: '#ff7300'}}/> Alterar Senha</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            { stepPass === 'collect-step' ?
              <>
                <MDBModalBody>
                  <p style={{fontWeight: 500, fontSize: '0.9rem', textAlign: "justify"}}>Mantenha sua conta segura e atualize sua senha. Digite sua senha antiga, escolha uma nova senha 
                    e repita-a para confirmar. Clique em 'Alterar Senha' para aplicar as alterações.</p>
                  <p style={{fontWeight: 600, fontSize: '0.7rem', color: '#d00'}}>Lembre-se de escolher uma senha forte e exclusiva.</p>
                  <input onChange={e => setCurrentPassword(e.target.value)} placeholder="Digite aqui sua senha atual" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px', marginBottom: '0.5rem'}} />
                  
                  <input onChange={e => setNewPassoword(e.target.value)} placeholder="Digite aqui sua senha nova senha" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px', marginBottom: '0.5rem'}} />
                  <input onChange={e => setRe_NewPassword(e.target.value)} placeholder="Digite aqui novamente sua nova senha" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px', marginBottom: '0.5rem'}} />  
                  <p className="text-center text-danger" style={{fontWeight: 600, fontSize: '0.8rem', marginTop: '1rem'}}>{error}</p>  
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color='primary' style={{borderRadius: '30px'}} onClick={toggleShow} outline>
                    Cancelar
                  </MDBBtn>
                  <MDBBtn color="danger" style={{borderRadius: '30px'}} onClick={() => changePass()}>Alterar senha</MDBBtn>
                </MDBModalFooter>
              </>
            : stepPass === 'waiting-response-step' ?
              <MDBModalBody>
                <LoaderDash text='Alterando sua senha, por favor aguarde.' logoSize='250px'/>
              </MDBModalBody>
            : stepPass === 'success-step' ?
                <>
                  <MDBModalBody className="text-center">
                    <MdVerified size={'5rem'} style={{marginTop: '2rem', color: '#ff7300'}}/>
                    <h4 style={{fontWeight: 700, marginTop: '2rem'}}>Senha alterada com sucesso!</h4>
                    <p style={{fontWeight: 600, fontSize: '0.7rem', color: '#d00'}}>Lembre-se de guardar sua senha em um local seguro.</p>
                  </MDBModalBody>    
                  <MDBModalFooter>
                    <MDBBtn color='primary' style={{borderRadius: '30px'}} onClick={toggleShow} outline>
                      Concluir
                    </MDBBtn>
                  </MDBModalFooter>
                </>
              : stepPass === 'error-step' ?
                <>
                  <MDBModalBody className="text-center">
                    <BiMessageAltError size={'5rem'} style={{marginTop: '2rem', color: '#d00'}}/>
                    <h4 style={{fontWeight: 700, marginTop: '2rem',}}>Ocorreu um erro ao alterar sua senha.</h4>
                    <p style={{fontWeight: 600, fontSize: '0.7rem', color: '#d00'}}>Entre em contato com o suporte ou tente novamente.</p>
                  </MDBModalBody>    
                  <MDBModalFooter>
                    <MDBBtn color='primary' style={{borderRadius: '30px', borderColor: '#ff7300', color: '#ff7300'}} onClick={() => setStepPass('collect-step')} outline>
                      Tentar novamente
                    </MDBBtn>
                  </MDBModalFooter>
                </> 
              :
                null                      
            }
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default DashboardNavbar;
