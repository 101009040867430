const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

const changePassword = async (authkey, currentPassword, newPassword) => {
    try{
        
        let headersList = {
            mode: 'cors', 
        }

        let bodyContent = JSON.stringify({"authkey" : authkey, "currentPass" : currentPassword, "newPassword" : newPassword });

        let response = await fetch(api + "platform/changepassword", { 
            method: "POST",
            body: bodyContent,
            headersList
        });
        
        let data = await response.json();

        return data.status;

    }catch (e){
        return false;
    }
}

export { changePassword }