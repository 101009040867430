import React from "react";
import { createRoot } from 'react-dom/client';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "react-multi-carousel/lib/styles.css";
import "react-image-lightbox/style.css";
import "./index.css";
import App from "./App";

import registerServiceWorker from './registerServiceWorker';

const container = document.getElementById("root");
const root = createRoot(container);


root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

//registerServiceWorker();