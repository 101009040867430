import React, { Component } from 'react';
import { MDBCol, MDBContainer, MDBFooter, MDBRow } from 'mdb-react-ui-kit';
import { FiInstagram } from 'react-icons/fi';
import { FaYoutube, FaFacebookF, FaHeart } from 'react-icons/fa';
import { BsWhatsapp } from 'react-icons/bs';

class Footer extends Component {


  render() {
      
    return (
      <MDBFooter style={{backgroundColor: '#ff7300', paddingTop: '2rem'}}>
        <MDBContainer>          
          <MDBRow className='justify-content-center'>
            <MDBCol md={4}>
              <p style={{textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 500, color: '#fff', marginTop: '3rem'}}>Vendas e Atendimento</p>
              <h2 style={{fontWeight: 700, color: '#fff'}}><a style={{color: '#f5f5f5'}} href='https://api.whatsapp.com/send?phone=5514998186060' target='_blank' rel='norefer'>14 99818-6060 <BsWhatsapp /></a></h2>
              <p style={{fontSize: '0.8rem', fontWeight: 500, color: '#fff'}}><a style={{color: '#f5f5f5'}} href='mailto:contato@imovelvaloriza.com.br'>contato@imovelvaloriza.com.br</a></p>
            </MDBCol>
            <MDBCol md={4}>
              <p style={{textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 500, color: '#fff', marginTop: '3rem'}}>Nossa central</p>
              <p style={{fontWeight: 600, color: '#fff', fontSize: '1rem'}}><a style={{color: '#f5f5f5'}} href='https://www.google.com/maps/place/Av.+Cel.+Clementino+Gonçalves,+2725+-+Santa+Cruz+do+Rio+Pardo,+SP,+18900-000/@-22.8781494,-49.6072281,19z/data=!3m1!4b1!4m6!3m5!1s0x94c0697cd0c37963:0x8da0bedaa76579d3!8m2!3d-22.8781506!4d-49.606583!16s%2Fg%2F11q9j2tw48' target='_blank' rel='norefer'>Av. Coronel Clementino Gonçalves, 2725 
                <br />Santa Cruz do Rio Pardo | SP</a></p>
            </MDBCol>
          </MDBRow>
          <MDBRow className='justify-content-center'>
            <MDBCol md={4} style={{paddingRight: '6rem'}}>
              <p style={{textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 500, color: '#fff', marginTop: '3rem'}}>Trabalhe Conosco</p>
              <p style={{fontWeight: 600, color: '#fff', fontSize: '0.9rem'}}>Entre em contato e confira quais vagas temos disponíveis.</p>
            </MDBCol>
            <MDBCol md={4}>
              <div style={{marginTop: '2rem'}}>
                <a href='https://www.facebook.com/todoimovelvaloriza/' target='_blank' rel="noopener noreferrer" className='deep-orange mr-3' 
                style={{padding: '0.5rem', backgroundColor: '#fff', borderRadius: '5px', color: '#ff7300', marginRight: '0.5rem'}}>
                  <FaFacebookF size={'1.5rem'} />
                </a>
                <a href='https://instagram.com/imovelvaloriza.com.br' target='_blank' rel="noopener noreferrer" className='deep-orange mr-3' 
                style={{padding: '0.5rem', backgroundColor: '#fff', borderRadius: '5px', color: '#ff7300', marginRight: '0.5rem'}}>
                  <FiInstagram size={'1.5rem'} />
                </a>
                <a href='https://www.youtube.com/channel/UCjzYWPguO3jQjeMHvl_ey1Q' rel="noopener noreferrer" target='_blank' className='deep-orange' 
                style={{padding: '0.5rem', backgroundColor: '#fff', borderRadius: '5px', color: '#ff7300'}}>
                  <FaYoutube size={'1.5rem'} />
                </a>
              </div>
              <p style={{fontWeight: 500, color: '#fff', fontSize: '0.7rem', marginTop: '3rem', textTransform: 'uppercase'}}>Feito com &nbsp;<FaHeart size={'1rem'} />&nbsp; pela Imóvel Valoriza</p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
            <p className='mb-3 mt-5 py-3 text-center' style={{color: '#fff', fontWeight: '600', fontSize: '0.5rem'}}>
              &copy; {new Date().getFullYear()} Copyright Imóvel Valoriza - CRECI J 35580. Todos os Direitos Reservados.
            </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBFooter>
          
    );
  }
}

function MiniFooter () {

  return (
    <MDBFooter style={{backgroundColor: '#ff7300', paddingTop: '2rem', bottom: '0px', position: 'relative'}}>
        <MDBContainer>
          <MDBRow>
            <MDBCol>
            <p className='mb-3 mt-5 py-3 text-center' style={{color: '#fff', fontWeight: '600', fontSize: '0.5rem'}}>
              &copy; {new Date().getFullYear()} Copyright Imóvel Valoriza - CRECI J 35580. Todos os Direitos Reservados.
            </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>  
    </MDBFooter>

  )  
}

export default Footer;
export { MiniFooter };
