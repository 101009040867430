import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { MDBContainer,MDBRow, MDBCol, MDBCard, MDBCardBody, } from "mdb-react-ui-kit";
import { DashboardPreloader } from "../components/preloader";
import { EmpreendimentoDashList, EmpreendimentoDestaqueList } from "../components/empreendimentos-list";
import Cookies from 'js-cookie';
import DashboardNavbar from "../components/dashboard-navbar";
import EmpreendimentoRegister from "../components/emprendimento-register";
import DashboardFooter from "../components/dashboard-footer";
import '../index.css';

const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

function Dashboard () {
  
    const [view, setView] = useState('dashboard');
    const [counter, setCounter] = useState(0);
    const [authName, setAuthName] = useState(null)
    const { route } = useParams();
    const id_adm = Cookies.get('id_adm');

    const updateDestaqueList = () => {
        setCounter(prevCounter => prevCounter + 1);
    };

    useEffect(()=> {

        if(route === undefined){
            setView('dashboard');
            document.title = 'Dashboard - Imóvel Valoriza';
        }else if(route === 'adicionar'){
            setView('adicionar_empreendimento');
            document.title = 'Adicionar Imóvel - Imóvel Valoriza';
        }else if(route === 'gerenciar'){
            setView('gerenciar_empreendimento');
            document.title = 'Gerenciar Imóvel - Imóvel Valoriza';
        }else if(route === 'destaques'){
            setView('destaques');
            document.title = 'Imóveis em Destaque - Imóvel Valoriza';
        }


        async function authSession () {
          try{
            let bodyContent = JSON.stringify({ "key" : id_adm  });

            let headersList = {
              mode: 'cors', 
            }
              
            let response = await fetch(api + "authsession", { 
              method: "POST",
              body: bodyContent,
              headersList
            });
              
            let data = await response.json();

            if(!data.auth){
              Cookies.remove('id_adm');
              window.location.href='/login';
            }else{
                setAuthName(data.userName);
            }
          }catch (error) {
            console.error(error);
          }
        }

        authSession();
        
    }, []);


    return(
    <>
        <DashboardNavbar username={authName} authkey={id_adm} />    
            {view === 'dashboard' ?
                <MDBContainer>                
                    <MDBRow className="justify-content-center" style={{paddingBottom: '5rem'}}>
                        <MDBCol md={10}>
                            <h4 style={{borderLeft: 'solid 0.4rem #ff7300', borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '3rem', fontWeight: 600}}>Bem vindo(a) a sua área de gerenciamento, o que deseja fazer agora?</h4>
                            <p style={{fontSize: '0.9rem', fontWeight: 600}}>Escolha uma das opções abaixo para começar.</p>
                        </MDBCol>                    
                        <MDBCol md={5}>
                            <MDBCard onClick={() => window.location.href = 'platform/adicionar'} className="hover-shadow" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '0.7rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #ff7300', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontWeight: 600,}}>Adicionar novo imóvel</h6>
                                    <p style={{ fontSize: '0.9rem', fontWeight: 600}}>Clique aqui para adicionar um novo imóvel a plataforma.</p>                                    
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>  
                        <MDBCol md={5}>
                            <MDBCard onClick={() => window.location.href = 'platform/gerenciar'} className="hover-shadow" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '0.7rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #ff7300', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontWeight: 600,}}>Gerenciar imóveis</h6>
                                    <p style={{ fontSize: '0.9rem', fontWeight: 600}}>Clique aqui para visualizar, editar ou excluir imóveis cadastrados na plataforma.</p>                                    
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol> 
                    </MDBRow>
                </MDBContainer>
            : view === 'adicionar_empreendimento' ?
                <EmpreendimentoRegister />
            : view === 'gerenciar_empreendimento' ?
                <>
                <MDBContainer fluid style={{width: '95%'}}>
                    <MDBRow className="justify-content-center">
                        <MDBCol md={8}>
                            <MDBCol md={10}>
                                <h4 style={{borderLeft: 'solid 0.4rem #ff7300', borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '3rem', fontWeight: 600}}>Gerenciamento de imóveis cadastrados</h4>
                                <p style={{fontSize: '0.9rem', fontWeight: 600}}>Escolha um imóvel para poder visualizar suas opções de gerenciamento.</p>
                            </MDBCol> 
                            <EmpreendimentoDashList authkey={id_adm} updateDestaqueList={updateDestaqueList}/>
                        </MDBCol>
                        <MDBCol md={4}>
                            <h4 style={{textAlign: 'center', fontWeight: 600, marginTop: '3rem',}}>Imóveis em destaque</h4>
                            <div className="d-flex justify-content-center">
                                <hr style={{color: '#ff7300', height: '0.3rem', width: '50%',}} />
                            </div>
                            <EmpreendimentoDestaqueList authkey={id_adm} updateDestaqueList={updateDestaqueList} counter={counter}/>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                </>
            :
                <DashboardPreloader />
            }
        <DashboardFooter />
    </>
    );
}

export default Dashboard;