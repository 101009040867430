import React from "react";
import { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Carousel from 'react-multi-carousel';


const FotosGallery = ({data, imovelID}) => {
  const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

  const images = data.split(';');

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(()=> {
    images.pop();
  },[images]);
  return (
    <div>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="full-width"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 3,
            partialVisibilityGutter: 30
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 2,
            partialVisibilityGutter: 30
          }
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {images.slice(0, 7).filter(image => image !== '').map((image, index) => (
          <div key={index} onClick={() => handleClick(index)} style={{ cursor: 'pointer' }}>
            <img
              width={'100%'}
              height='250px'
              src={api + 'images/' + imovelID + '/' + image}
              style={{ border: '2px solid #f5f5f5', borderTop: 'none', borderBottom: 'none' }}
            />
          </div>
        ))}
        {images.length > 1 ? (
          <div onClick={() => handleClick(0)} style={{ backgroundImage: `url(${api + 'images/' + imovelID + '/' + images[0]})` }} className='gallery-button_all'>
            <div>
              <span className="text-center">
                Ver todas as fotos
              </span>
            </div>
          </div>
        ) : null}
      </Carousel>
{//margin: '2px', padding: '0.1rem', position: 'relative', userSelect: 'none', gallery-button_all}
}
      </div>
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={api + 'images/'+imovelID+'/' + currentImage}
          nextSrc={api + 'images/'+imovelID+'/' + nextImage}
          prevSrc={api + 'images/'+imovelID+'/' + prevImage}
          wrapperClassName='wrapper-lightbox'
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
}

export default FotosGallery;
