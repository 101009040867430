import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import HomePage from './pages/HomePage';
import Buscar from './pages/BuscarPage';
import Imovel from './pages/ImovelPage';
import Vender from './pages/VenderPage';
import Lotear from './pages/LotearPage';
import Login from './pages/LoginPage';
import Dashboard from './pages/DashboardPage';

function App() {
 
return (
    <>
      <div className="App">
        {/* Adicione o componente CookieConsent aqui */}
        <CookieConsent
          location="bottom"
          buttonText="Aceitar"
          cookieName="cookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#f5f5f5", fontWeight: 600, fontSize: "13px", backgroundColor: '#ff7300', borderRadius: '15px' }}
          expires={150}
        >
          Este site utiliza cookies para melhorar a sua experiência de navegação.
          {" "}
          <span style={{ fontSize: "13px", marginLeft: '0.5rem' }}>
            <a href="/privacidade.pdf">Saiba mais</a>
          </span>
        </CookieConsent>

        {/* Restante da sua aplicação */}
      </div>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<HomePage />}/>
          <Route exact path='/buscar/' element={<Buscar />}/>
          <Route exact path='/buscar/:tipo/:finalidade/:cidade/:classificar' element={<Buscar />}/>
          <Route exact path='/buscar/:tipo/:finalidade/:cidade/' element={<Buscar />}/>
          <Route path='/buscar/imovel/:id' element={<Imovel />}/>
          <Route path='/vender' element={<Vender />}/>
          <Route path='/incorporador' element={<Lotear />}/>
          <Route path='/login' element={<Login />} />
          <Route path='/platform' element={<Dashboard />} />
          <Route path='/platform/:route' element={<Dashboard />} />
          <Route render={function() { return <h1>Parece que o conteúdo que você está procurando não existe.</h1>; }} />
        </Routes>
      </BrowserRouter>
    </>
    );  
}

export default App;
