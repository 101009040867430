import { MDBContainer, MDBRow, MDBCol,} from "mdb-react-ui-kit";
import '../index.css';

function DashboardFooter(){
    return(
        <>

        <div style={{backgroundColor: '#fbfbfb', width: '100%', borderTop: 'solid 1px #dcdcdc', paddingTop: '1rem', marginTop: '2rem'}}>
            <MDBContainer>
                <MDBRow className="justify-content-center" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.6rem', color: '#2B2F33'}}>
                    <MDBCol md={12} className='text-center'>
                        <p>© {(new Date().getFullYear())} Copyright Mage Tech - Tech Development - Suporte: (11) 9 9008-3256. Todos os Direitos Reservados.</p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
        </>
    );
}

export default DashboardFooter;