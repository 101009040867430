import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MDBContainer, MDBCol, MDBRow, MDBCardBody, MDBCard, MDBBtn } from 'mdb-react-ui-kit';
import './HomePage.css';
import '../index.css';
import { AiOutlineFileText } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { BiBuildingHouse } from 'react-icons/bi';
import { TbVector, TbBuildingEstate, TbArrowsDownUp, TbBuildingCommunity } from 'react-icons/tb';
import { IoFilterOutline} from 'react-icons/io5';
import { IoMdClose } from 'react-icons/io';
import { TbInputSearch } from 'react-icons/tb';
import { slideInUp} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import NavBar from '../components/navbar';
import Footer from '../components/footer';


const styles = {
  slideInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInUp, 'slideInUp')
  },
}


const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

const Buscar = () => {
  
  const { cidade, finalidade, tipo, classificar } = useParams();
  const [ CidadesData, setCidadesData ] = useState(false)
  const [ ImoveisData, setImoveisData ] = useState([]);
  const [ ImoveisStatus, setImoveisStatus ] = useState(false);
  const [ resultSize, setResultSize ] = useState(null);
  const [ totalPaginas, setTotalPaginas ] = useState(0);
  const [ pagina, setPagina ] = useState(1);
  const [ LoaderShow, setLoaderShow ] = useState(true);
  const [ FiltrosArray, setFiltrosArray] = useState([ cidade, finalidade, tipo, classificar ]);
  const [exibirSideBar, setExibirSideBar] = useState(false);

  const cidadesCharger = () => {
    fetch(api + "cidades")
    .then((response) => response.json())
    .then((response) => {        
      setCidadesData(response);
    })
    .catch((rejected) => {
      console.log(rejected);
    }); 
  }

  const AtualizarFiltros = (indice, novoValor) => {
    setFiltrosArray(
      FiltrosArray.map((valor, i) => {
        if (i === parseInt(indice)) {
          return novoValor;
        } else {
          return valor;
        }
      })
    );
  }
  

  const carregarMaisResultados = () => {
    if(totalPaginas > pagina){
      let page = (pagina + 1);
      setPagina(page);
      imoveisCharger(tipo, finalidade, cidade, classificar, page);
    }
  }

  const imoveisCharger = (tipo, finalidade, cidade, classificar, page) => {    
    
    setLoaderShow(true);
        
    if(cidade === undefined && tipo === undefined && finalidade === undefined && classificar === undefined){

      fetch(api + "imoveis/pagina/" + page)
        .then((response) => response.json())
        .then((response) => {        
          if(page > 1){
            setImoveisData(ImoveisData.concat(response.imoveis));
          }else{
            setImoveisData(response.imoveis);
          }
          setImoveisStatus(response.query.status);
          setTotalPaginas(response.query.paginas_total);
          setResultSize(response.query.result_size);
          setLoaderShow(false);
        })
        .catch((rejected) => {
          console.log(rejected);
        }); 
    }else{

      let filtros = [];

      if(cidade === undefined || cidade === 'undefined') {
        filtros[0] = "all";
      }else{
        filtros[0] = cidade;
      }

      if(finalidade === undefined || finalidade === 'undefined') {
        filtros[1] = "all";
      }else{
        filtros[1] = finalidade;
      }

      if(tipo === undefined || tipo === 'undefined') {
        filtros[2] = "all";
      }else{
        filtros[2] = tipo;
      }

      if(classificar === undefined || classificar === 'undefined') {
        filtros[3] = '';
      }else{
        filtros[3] = '&'+classificar;
      }
        
      fetch(api + "imoveis/" + filtros[0] + '&' + filtros[1] + '&' + filtros[2] + filtros[3] + "/pagina/" + page)
        .then((response) => response.json())
        .then((response) => {        
          if(page > 1){
            setImoveisData(ImoveisData.concat(response.imoveis));
          }else{
            setImoveisData(response.imoveis);
          }
          setImoveisStatus(response.query.status);
          setTotalPaginas(response.query.paginas_total);
          setResultSize(response.query.result_size);
          setLoaderShow(false);
        })
        .catch((rejected) => {
          console.log(rejected);
        }); 
    }

  }

  const acessarImovel = (key) => {
    window.location.href = '/buscar/imovel/'+key;
  }

  const filtrarImoveis = () => {
    const url = '/buscar/' + ((FiltrosArray[2] === 'undefined' || FiltrosArray[2] === undefined ? 'all' : FiltrosArray[2])) + '/' + ((FiltrosArray[1] === 'undefined' || FiltrosArray[1] === undefined ? 'all' : FiltrosArray[1])) + '/' + ((FiltrosArray[0] === 'undefined' || FiltrosArray[0] === undefined ? 'all' : FiltrosArray[0])) + ((FiltrosArray[3] === 'undefined' || FiltrosArray[3] === undefined ? '' : '/' + FiltrosArray[3]));
    window.location.href = url;
  }
  

  useEffect(() => {
    imoveisCharger(tipo, finalidade, cidade, classificar, pagina);
    cidadesCharger();
  }, [])
  

  return (
    <>

      <Helmet>
        <meta name="description" content={"Encontre o imóvel perfeito com a Imóvel Valoriza! Somos a imobiliária digital que oferece venda e aluguel de casas, apartamentos, terrenos, propriedades rurais e comerciais no interior de São Paulo. Descubra o lar dos seus sonhos agora mesmo!"} />
      </Helmet>  
      <NavBar />  
      <section style={{marginTop: '3rem',}}>
        <MDBContainer>
          <MDBRow className='exc-mobile'>
            <label className='mb-3' style={{fontWeight: 500, fontSize: '0.8rem',}}><i><span style={{fontWeight: 600,}}>Dica:</span> use nossos filtros para achar o imóvel perfeito para você!</i></label>
            <MDBCol md={8}>
              <div style={{border: '1px solid #d0d0d0', borderRadius: '30px', display: 'inline-block', padding: '0.3rem', width: '80%'}}>
                <MDBRow className='justify-content-center'>
                  <MDBCol size={4}>                    
                    <select id='type' onChange={e => AtualizarFiltros(2, e.target.value ) } onClick={e => AtualizarFiltros(2, e.target.value ) } defaultValue={FiltrosArray[2] && FiltrosArray[2] !== 'all' ? FiltrosArray[2] : 'all'} className="form-control select-ios text-center select" style={{border: 'none', fontSize: '0.8rem', display: 'inline', borderRadius: '30px', fontWeight: 500}}>
                      <option value={'null'} disabled>Tipo</option> 
                      <option value={'all'}>Todos os tipos</option>
                      <option value={'Apartamento'}>Apartamento</option>
                      <option value={'Casa'}>Casa</option>
                      <option value={'Comercial'}>Comercial</option>
                      <option value={'Lazer'}>Lazer</option>
                      <option value={'Terreno'}>Terreno</option>
                      <option value={'Rural'}>Rural</option>
                    </select>
                  </MDBCol>  
                  <MDBCol size={4}>                    
                    <select id='finality' onChange={e => AtualizarFiltros(1, e.target.value ) } onClick={e => AtualizarFiltros(1, e.target.value ) } defaultValue={FiltrosArray[1] && FiltrosArray[1] !== 'all' ? FiltrosArray[1] : 'all'} className="form-control select-ios text-center select" style={{border: 'none', fontSize: '0.8rem', display: 'inline', borderRadius: '30px', fontWeight: 500}}>
                      <option value={'null'} disabled>Finalidade</option> 
                      <option value={'all'}>Todas as finalidades</option>
                      <option value={'Locação'}>Alugar</option>
                      <option value={'Comprar'}>Comprar</option>
                    </select>
                  </MDBCol> 
                  <MDBCol size={4}>
                    <select id='location' onChange={e => AtualizarFiltros(0, e.target.value ) } onClick={e => AtualizarFiltros(0, e.target.value ) } defaultValue={FiltrosArray[0] && FiltrosArray[0] !== 'all' ? FiltrosArray[0] : 'all'} className="form-control select-ios text-center select" style={{border: 'none', fontSize: '0.8rem', display: 'inline', borderRadius: '30px', fontWeight: 500}}>
                      <option value={'null'} disabled>Escolha a cidade</option>
                      <option value={'all'}>Todas as cidades</option>
                      {CidadesData.result &&  CidadesData.result.map((CIDADE) => <option key={CIDADE.CIDADE} value={CIDADE.CIDADE}>{CIDADE.CIDADE}</option>)}
                    </select>
                  </MDBCol>  
                </MDBRow>
              </div>
              <button onClick={() => filtrarImoveis()} style={{backgroundColor: '#65005a', borderRadius: '50%', color: '#fff', border: 'none', width: '37px', height: '37px', marginLeft: '1rem'}}>
                <BsSearch style={{color: '#fff', fontSize: '1.1rem'}}/>
              </button>
            </MDBCol>
            <MDBCol md={4}>
              <div style={{border: '1px solid #d0d0d0', borderRadius: '30px', float: 'right', display: 'inline-block', padding: '0.3rem',  width: '60%'}}>
                <select id='location' onChange={e => AtualizarFiltros(3, e.target.value )} onClick={e => AtualizarFiltros(3, e.target.value )} defaultValue={FiltrosArray[3] && FiltrosArray[3] !== 'all' ? FiltrosArray[3] : 'null'} className="form-control select-ios text-center select" style={{border: 'none', fontSize: '0.8rem', display: 'inline', borderRadius: '30px', fontWeight: 500, width: '100%'}}>
                  <option value='null' disabled>Classificar por</option>
                  <option value={'recentes'}>Recentes</option>
                  <option value={'antigos'}>Antigos</option>
                  <option value={'preco-menor'}>Preço menor</option>
                  <option value={'preco-maior'}>Preço maior</option>
                </select>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <MDBContainer>
          <div className='exc-desktop text-center'>
            <button onClick={() => setExibirSideBar(!exibirSideBar)} style={{ backgroundColor: '#ff7300', borderRadius: '30px',border: 'none', color: '#f5f5f5', padding: '0.5rem 1rem', fontWeight: 600 }} ><IoFilterOutline style={{marginRight: '0.5rem', fontSize: '1.3rem'}}/> Filtrar minha busca</button>
          </div>  
          <div className='gradient title-gradient' style={{height: '2rem', marginTop: '2rem', marginBottom: '1.2rem', display:  LoaderShow ? 'block' : 'none'}}></div>
            <p style={{fontWeight: 500, fontSize: '0.9rem', display: LoaderShow ? 'none' : 'block', marginBottom: '2rem', marginTop: '2rem'}}> 
                <i>{resultSize > 1 ? 'Encontramos ' + resultSize + ' imóveis que você pode gostar' : resultSize == 1 ?  'Achamos este incrível imóvel para você' : 'Ops, parece que não encontramos nenhum imóvel.'}</i>
            </p>        
        </MDBContainer>     
      </section>

      <section>
        <MDBContainer style={{width: '100%'}}>
          { LoaderShow ?
          <MDBRow className='justify-content-center'>
            <MDBCol  md="4" className="mb-5">
              <MDBCard style={{borderRadius: '15px'}}>
                <div style={{ width:'100%', height: '13rem', borderRadius: '15px 15px 0px 0px'}} className='gradient'></div>
                <MDBCardBody>
                    <div style={{width: '100%', height: '2rem'}} className='gradient'></div>
                    <div className='gradient' style={{width: '50%', height: '1rem', marginTop: '1.2rem'}}>
                    </div>
                    <div className='gradient' style={{width: '100%', height: '1rem', marginTop: '1.2rem', marginBottom: '1rem'}}>
                    </div>
                    <div className='gradient' style={{width: '60%', height: '2rem', marginTop: '1.2rem', marginBottom: '1rem'}}>
                    </div>
                  </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol  md="4" className="mb-5">
              <MDBCard style={{borderRadius: '15px'}}>
                <div style={{ width:'100%', height: '13rem', borderRadius: '15px 15px 0px 0px'}} className='gradient'></div>
                <MDBCardBody>
                    <div style={{width: '100%', height: '2rem'}} className='gradient'></div>
                    <div className='gradient' style={{width: '50%', height: '1rem', marginTop: '1.2rem'}}>
                    </div>
                    <div className='gradient' style={{width: '100%', height: '1rem', marginTop: '1.2rem', marginBottom: '1rem'}}>
                    </div>
                    <div className='gradient' style={{width: '60%', height: '2rem', marginTop: '1.2rem', marginBottom: '1rem'}}>
                    </div>
                  </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol  md="4" className="mb-5">
              <MDBCard style={{borderRadius: '15px'}}>
                <div style={{ width:'100%', height: '13rem', borderRadius: '15px 15px 0px 0px'}} className='gradient'></div>
                <MDBCardBody>
                    <div style={{width: '100%', height: '2rem'}} className='gradient'></div>
                    <div className='gradient' style={{width: '50%', height: '1rem', marginTop: '1.2rem'}}>
                    </div>
                    <div className='gradient' style={{width: '100%', height: '1rem', marginTop: '1.2rem', marginBottom: '1rem'}}>
                    </div>
                    <div className='gradient' style={{width: '60%', height: '2rem', marginTop: '1.2rem', marginBottom: '1rem'}}>
                    </div>
                  </MDBCardBody>
                </MDBCard>
            </MDBCol>
          </MDBRow>
          :
          <>
            <MDBRow className='justify-content-center'> 
              {ImoveisStatus && ( ImoveisData.map(imoveis => (
              <MDBCol md={6} className="mb-5" key={imoveis.ID} onClick={() => acessarImovel((imoveis.SLUG ? imoveis.SLUG : imoveis.ID))} style={{cursor: 'pointer'}}>
                <div style={{borderRadius: '15px', border: 'solid #e5e5e5 1px'}} className='hover-shadow'>
                  <MDBRow>
                    <MDBCol size={6}>
                      <div style={{borderRadius: '15px 0px 0px 15px', height: '100%', width: '100%',  backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundImage: 'url( "https://imovelvaloriza.com.br/api/v2/images/'+imoveis.ID+'/'+imoveis.FOTOS.split(';')[0]+'")' }}></div>
                    </MDBCol>
                    <MDBCol size={6} style={{borderRadius: '15px'}}>
                      <div>      
                        <p className='text-truncate' style={{ marginTop: '2rem', fontWeight: 600, fontSize: '0.7rem', color: '#4B515D', textTransform: 'uppercase'}}>
                          <span name='cidade'>{imoveis.CIDADE}</span>
                        </p>                
                        <p style={{marginRight: '0.5rem', lineHeight: '1.5rem', fontWeight: 600, marginBottom: '0.4rem', color: 'darkorange'}} ><span name='nome' className='title-responsive'>{imoveis.NOME}</span></p>
                        <p style={{marginTop: '2rem', marginBottom: '1rem', fontWeight: 500, fontSize: '0.88rem', color: '#4B515D'}}>
                          <span name='metragem' style={{float: 'left',padding: '0.5rem', fontWeight: 700, fontSize: '0.8rem'}}><TbVector />&nbsp; {imoveis.AREA_TOTAL} m²</span> {imoveis.TIPO_IMOVEL === 'Lote' ? <span style={{float: 'right', marginRight: '1rem', backgroundColor: 'darkorange', color: '#fff', padding: '0.5rem', borderRadius: '30px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 700}} name='status'>status lote</span> : null }
                        </p>
                        <br />
                        <p style={{ marginTop: '1.5rem', fontWeight: 700, fontSize: '0.7rem', color:'#65005A', textTransform: 'uppercase'}}>
                          {imoveis.LEGENDA_VALOR} <br />                     
                        <span style={{ fontWeight: 700, color: '#ff6f00', marginBottom: '1.2rem',}} className='value-responsive'>{imoveis.VALOR}</span>
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>  
              )))}
            </MDBRow>  
            <MDBRow className='text-center'>
              <MDBCol style={{padding: '0 0 2rem 0'}}>
                { pagina !== totalPaginas ?
                  <>
                    <button onClick={() => carregarMaisResultados()} style={{borderRadius: '30px', border: 'none', backgroundColor: '#ff730044', padding: '1rem 2rem', color: '#ff7300', fontWeight: '600'}}>
                    <TbBuildingCommunity style={{ marginRight: '0.5rem', fontSize: '2rem'}} /> Quero ver mais imóveis</button>
                  </>
                  :
                  <p style={{background: '#f7f7f7', margin: '1rem 1rem', borderRadius: '30px', padding: '1.3rem 1.3rem', fontWeight: 500}}><span>🤔</span> Não achou o que procurava? <br /> Entre em contato com a gente ou altere os filtros e tente novamente!</p>
                }
              </MDBCol>
            </MDBRow>
          </>  
          }   
        </MDBContainer>
      </section>
    <Footer />
    {exibirSideBar &&   
      <StyleRoot>
        <div className='sidebar' style={styles.slideInUp}>   
          <MDBContainer>   
            <MDBRow className='text-center'>
              <MDBCol size={2}>
                <span className='mt-3 shadow' onClick={() => setExibirSideBar(!exibirSideBar)} style={{display: 'flex', height: '35px', width: '35px', borderRadius: '50%', backgroundColor: '#ff7300', alignItems: 'center', justifyContent: 'center'}}><IoMdClose style={{fontSize: '1.5rem', color: '#f5f5f5', fontWeight: 700}} /></span>
              </MDBCol>
              <MDBCol size={8}>
                <h3 className='mt-3' style={{fontWeight: 700, paddingTop: '0.2rem'}}>Filtrar minha busca</h3>
              </MDBCol>
            </MDBRow>      
            <MDBRow style={{background: '#f7f7f7', margin: '3rem 1rem', borderRadius: '30px', padding: '2rem 1.3rem', marginBottom: '1rem'}}>
                <MDBCol size={12}>
                  <h4><BiBuildingHouse style={{marginRight: '0.5rem', color: '#ff7300'}}/> Filtrar por tipo</h4>
                  <select onChange={e => AtualizarFiltros(2, e.target.value )} onClick={e => AtualizarFiltros(2, e.target.value )} id='type' className='form-control mt-2 select' defaultValue={FiltrosArray[2] && FiltrosArray[2] !== 'all' ? FiltrosArray[2] : 'all'} style={{border: 'none', display: 'inline', borderRadius: '30px', fontWeight: 500, backgroundColor: '#fff'}}>
                    <option value={'null'} disabled>Tipo</option> 
                    <option value={'all'}>Todas os tipos</option>
                    <option value={'Apartamento'}>Apartamento</option>
                    <option value={'Casa'}>Casa</option>
                    <option value={'Comercial'}>Comercial</option>
                    <option value={'Lazer'}>Lazer</option>
                    <option value={'Terreno'}>Terreno</option>
                    <option value={'Rural'}>Rural</option>
                  </select>              
                </MDBCol>
                <MDBCol size={12}>
                  <h4 style={{marginTop: '3rem'}}><AiOutlineFileText style={{marginRight: '0.5rem', color: '#ff7300'}}/>Filtrar por Finalidade</h4>
                  <select onChange={e => AtualizarFiltros(1, e.target.value )} onClick={e => AtualizarFiltros(1, e.target.value )} id='finality' defaultValue={FiltrosArray[1] && FiltrosArray[1] !== 'all' ? FiltrosArray[1] : 'all'} className="form-control mt-2 select" style={{border: 'none', display: 'inline', borderRadius: '30px', fontWeight: 500, backgroundColor: '#fff'}}>
                    <option value={'null'} disabled>Finalidade</option> 
                    <option value={'all'}>Todas as finalidades</option>
                    <option value={'Locação'}>Alugar</option>
                    <option value={'Comprar'}>Comprar</option>
                  </select>
                </MDBCol>
                <MDBCol size={12}>
                  <h4 style={{marginTop: '3rem'}}><TbBuildingEstate style={{marginRight: '0.5rem', color: '#ff7300'}}/> Filtrar por Cidade</h4>
                  <select onChange={e => AtualizarFiltros(0, e.target.value )} onClick={e => AtualizarFiltros(0, e.target.value )} id='location' defaultValue={FiltrosArray[0] && FiltrosArray[0] !== 'all' ? FiltrosArray[0] : 'all'} className="form-control mt-2 select" style={{border: 'none', display: 'inline', borderRadius: '30px', fontWeight: 500, backgroundColor: '#fff'}}>
                    <option value={'null'} disabled>Escolha a cidade</option>
                    <option value={'all'}>Todas as cidades</option>
                    {CidadesData &&  CidadesData.result.map((CIDADE) => <option key={CIDADE.CIDADE} value={CIDADE.CIDADE}>{CIDADE.CIDADE}</option>)}
                  </select>              
                </MDBCol>
            </MDBRow>
            <MDBRow style={{background: '#f7f7f7', margin: '1rem 1rem', borderRadius: '30px', padding: '2rem 1.3rem'}}>
              <MDBCol>
                  <h4><TbArrowsDownUp style={{marginRight: '0.5rem', color: '#ff7300'}}/> Classificar por</h4>
                  <select id='location' onChange={e => AtualizarFiltros(3, e.target.value )} onClick={e => AtualizarFiltros(3, e.target.value )} defaultValue={FiltrosArray[3] && FiltrosArray[3] !== 'all' ? FiltrosArray[3] : 'null'}  className="form-control mt-2 select" style={{border: 'none', display: 'inline', borderRadius: '30px', fontWeight: 500, backgroundColor: '#fff'}}>
                    <option value='null' disabled>Como deseja classificar?</option>
                    <option value={'recentes'}>Recentes</option>
                    <option value={'antigos'}>Antigos</option>
                    <option value={'preco-menor'}>Preço menor</option>
                    <option value={'preco-maior'}>Preço maior</option>
                  </select>
              </MDBCol>
            </MDBRow>            
            <MDBRow className='text-center'>
                <MDBCol>
                  <button onClick={() => filtrarImoveis()}  style={{backgroundColor: '#ff7300', borderRadius: '30px',border: 'none', color: '#f5f5f5', padding: '0.5rem 1rem', fontWeight: 600, width: '90%'}}><TbInputSearch style={{marginRight: '0.5rem', fontSize: '1.3rem'}}/> Encontrar imóveis</button>
                </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </StyleRoot>
    }
    </>
  );
  
} 



export default Buscar;
