import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { MDBBtn, MDBContainer, MDBCol, MDBRow, MDBModal, MDBModalDialog, MDBModalContent, MDBModalBody, MDBModalHeader, MDBModalTitle,} from 'mdb-react-ui-kit';
import { SiWhatsapp } from 'react-icons/si';
import { MdOutlineRocketLaunch } from 'react-icons/md';
import { TiFlashOutline } from 'react-icons/ti';
import { BiMessageSquareCheck } from 'react-icons/bi';
import { BsPersonFill, BsCalendar2Date, BsWhatsapp } from 'react-icons/bs';
import { IoPhonePortraitSharp, IoLocationOutline } from 'react-icons/io5'
import { MdAlternateEmail, } from 'react-icons/md';
import { AiOutlineCheck, AiOutlineYoutube } from 'react-icons/ai'; 
import { CgSandClock } from 'react-icons/cg';
import { useParams } from "react-router-dom";
import './HomePage.css';
import '../index.css';
import IframeModal from '../components/iframeModal';
import Loader from '../components/loader';
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import FotosGallery from '../components/gallery';
import IconTamanho from '../img/arquitetura.png';
import IconRua from '../img/rua.png';
import IconVista from '../img/vista.svg';
import IconLazer from '../img/lazer.svg';

const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");


const Imovel = () => {
  const { id } = useParams();
  const [ IsLoad, setIsLoad ] = useState(false);
  const [ nome, setNome ] = useState('');
  const [ telefone, setTelefone ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ ModalAgendar, setModalAgendar] = useState(false);
  const [ formstep, setFormStep ] = useState('initial');
  const [ data, setdata] = useState({FOTOS: ''});
  const [showVideo, setShowVideo] = useState(false);

  const toggleShowVideo = () => { setShowVideo(!showVideo); };
  const toggleShow = () => setModalAgendar(!ModalAgendar);

  const dataCharger = () => {       
    setIsLoad(false);
    fetch(api + "imovel/" + id,{
      method: "GET",
    }
    )
      .then((response) => response.json())
      .then((response) => {
        setdata(response);
        document.title = response.NOME + ' - Imóvel Valoriza';
        setIsLoad(true);
      })
      .catch((rejected) => {
        console.log(rejected);
      }); 

  }

  const enviarContato = () => {
    
    setFormStep('sending');

    const dataToSend = {
      nome: nome,
      email: email,
      telefone: telefone,
      url: api + "comprar/imovel/" + (data.SLUG ? data.SLUG : data.ID),
      tipo: "imovel"
    };
    
    const options = {
      method: "POST",
      body: JSON.stringify(dataToSend),
      mode: 'cors'
    };
    
    fetch(api + 'enviar-email', options)
      .then(response => response.json())
      .then(data => data.status ? setFormStep('final') : setFormStep('error'))
      .catch(error => console.error(error));
  }

  const newLink = (url) => {
    window.open(url, '_blank');
  }
  
  useEffect(() => {
    dataCharger();
  }, []);

  return (
      <>
        { data.DESCRICAO &&
          <Helmet>
            <meta name="description" content={data.DESCRICAO.slice(0, 139) + '...'} />
          </Helmet>     
        } 
        <NavBar />  
        { IsLoad ?
        <section>
          <FotosGallery data={data.FOTOS} imovelID={data.ID}/>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl={11} style={{marginTop: '2rem', marginLeft: '2rem'}}>
                <div className='ml-2'>
                  { data.YOUTUBE_URL ?
                    <>
                      <MDBBtn onClick={toggleShowVideo} style={{borderRadius: '30px', fontWeight: 600, textTransform: 'initial', borderColor: '#ff7300', color: '#ff7300', marginRight: '0.5rem'}} outline><AiOutlineYoutube />&nbsp; Vídeo</MDBBtn>
                    </>
                  : null}
                  { data.MAPS_URL ?
                  <MDBBtn onClick={() => newLink(data.MAPS_URL)} style={{borderRadius: '30px', fontWeight: 600, textTransform: 'initial', borderColor: '#ff7300', color: '#ff7300'}} outline><IoLocationOutline />&nbsp; Maps</MDBBtn>
                  : null}
                  
                </div>
              </MDBCol>
              <IframeModal url={data.YOUTUBE_URL} toggleShow={toggleShowVideo} modalActive={showVideo} />
            </MDBRow>
          </MDBContainer>
          <MDBContainer>
            <MDBRow className='justify-content-center'>
              <MDBCol xl={11}>
                <div className='ml-2'>
                  <h3 style={{fontWeight: '800', marginBottom: '1rem', marginTop: '3rem', color: '#65005A', fontSize: '2rem'}}>{data.NOME}</h3>
                  <p style={{fontWeight: '500', fontSize: '0.9rem', marginTop: '0rem', color: '#353535'}}><span name='bairro'>{data.BAIRRO}</span></p>
                  <p style={{fontWeight: '500', fontSize: '0.9rem', marginTop: '-0.7rem', color: '#757575'}}><span name='cidade'>{data.CIDADE}</span></p>
                  <h2 style={{fontWeight: '800', marginTop: '1rem', fontSize: '2.3rem', color: '#ff6f00'}}><span style={{ color:'#65005A', fontSize: '1.15rem'}} className='dark-text'>{data.LEGENDA_VALOR}</span>
                   {data.LEGENDA_VALOR ? <br /> : null } {data.VALOR}</h2>
                </div>
                  <MDBRow>
                    <MDBCol md={6}  style={{paddingTop: '3rem'}}>
                      <MDBRow>
                        <MDBCol size={2}>
                          <div className='icon-circle'>
                            <span>
                              <img src={IconTamanho} style={{width: '2rem', height: 'auto'}}/>
                            </span>
                          </div>
                        </MDBCol>
                        <MDBCol size={10}>
                          <div style={{paddingLeft: '1rem'}}>
                            <span style={{fontWeight: 500, fontSize: '0.75rem', fontWeight: '600'}}>Tamanho</span> <br />
                            <span style={{fontWeight: 600}}>{data.AREA_TOTAL}m²</span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md={6}  style={{paddingTop: '3rem'}}>
                      <MDBRow>
                        <MDBCol size={2}>
                          <div className='icon-circle'>
                            <span>
                              <img src={IconRua} style={{width: '2rem', height: 'auto'}}/>
                            </span>
                          </div>
                        </MDBCol>
                        <MDBCol size={10}>
                          <div style={{paddingLeft: '1rem'}}>
                            <span style={{fontWeight: 500, fontSize: '0.75rem', fontWeight: '600'}}>Acesso</span> <br />
                            <span style={{fontWeight: 600}}>{data.RUA}</span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    { data.VISTA !== undefined && data.VISTA !== 'null' && data.VISTA !== null && data.VISTA !== '' ?
                      <MDBCol md={6} style={{paddingTop: '3rem'}}>
                        <MDBRow>
                          <MDBCol size={2}>
                            <div className='icon-circle'>
                              <span>
                                <img src={IconVista} style={{width: '2rem', height: 'auto'}}/>
                              </span>
                            </div>
                          </MDBCol>
                          <MDBCol size={10}>
                            <div style={{paddingLeft: '1rem'}}>
                              <span style={{fontWeight: 500, fontSize: '0.75rem', fontWeight: '600'}}>Vista</span> <br />
                              <span style={{fontWeight: 600, whiteSpace: 'pre-wrap'}}>{data.VISTA}</span>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    :
                      null
                    }
                    { data.LAZER !== undefined && data.LAZER !== 'null' && data.LAZER !== null && data.LAZER !== '' ?
                      <MDBCol md={6} style={{paddingTop: '3rem'}}>
                        <MDBRow>
                          <MDBCol size={2}>
                            <div className='icon-circle'>
                              <span>
                                <img src={IconLazer} style={{width: '2rem', height: 'auto'}}/>
                              </span>
                            </div>
                          </MDBCol>
                          <MDBCol size={10}>
                            <div style={{paddingLeft: '1rem'}}>
                              <span style={{fontWeight: 500, fontSize: '0.75rem', fontWeight: '600'}}>Lazer</span> <br />
                              <span style={{fontWeight: 600, whiteSpace: 'pre-wrap'}}>{data.LAZER}</span>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    :
                      null
                    }
                  </MDBRow>
              </MDBCol>
              <MDBCol xl={11}>
                <div style={{paddingTop: '5rem'}}>
                  <h5 style={{fontWeight: '700', marginBottom: '2rem'}}>Descrição:</h5>
                  <p style={{fontWeight: 500, fontSize: '0.9rem', whiteSpace: 'pre-wrap',}}>{data.DESCRICAO}</p>
                </div>
                <hr style={{marginTop: '3.5rem', height: '0.25rem'}}/>
                <div style={{paddingTop: '2rem'}}>
                  <h5 style={{fontWeight: '700', marginBottom: '2rem'}}>Conheça o bairro:</h5>
                  <p style={{fontWeight: 500, fontSize: '0.9rem', whiteSpace: 'pre-wrap',}}>{data.SOBRE_LOCALIZACAO}</p>
                </div>
                <hr style={{marginTop: '3.5rem', height: '0.25rem', backgroundColor: '#ff7300'}} className='exc-mobile'/>
                <div style={{paddingTop: '2rem', paddingBottom: '5rem'}} className='exc-mobile'>
                <h5 style={{fontWeight: '700', marginBottom: '1rem', color: '#f5f5f5', textTransform: 'uppercase',}}><span style={{backgroundColor: '#ff7300', padding: '0.3rem 1rem', borderRadius: '20px'}}><MdOutlineRocketLaunch style={{color: '#f5f5f5', marginRight: '0.5rem',}}/> Saia na frente</span></h5>
                  <MDBRow className=''>
                    <MDBCol md={5} className='mt-2'>
                      <MDBBtn onClick={() => toggleShow()} style={{width: '100%', height: '4rem', textTransform: 'initial', fontSize: '1.05rem', backgroundColor: '#ff7300'}}><BsCalendar2Date size={'1.3rem'} />&nbsp; Agendar visita</MDBBtn>
                    </MDBCol>
                    <MDBCol md={5} className='mt-2'>
                      <MDBBtn onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=5514998186060&text=Olá,%20tenho%20interesse%20no%20imóvel%20'+data.NOME+',%20Ref:%20'+data.ID+'.'} style={{width: '100%', height: '4rem', textTransform: 'initial', fontSize: '1.05rem', backgroundColor: '#25D366'}}><SiWhatsapp size={'1.3rem'} />&nbsp; Atendimento WhatsApp</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
              <div className='exc-desktop nav_footer-mobile'>
                <hr style={{marginTop: '0rem', height: '0.25rem', backgroundColor: '#ff7300', width: '100%'}} />
                <div style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
                  <p style={{fontWeight: '700', marginBottom: '1rem', color: '#f5f5f5', textTransform: 'uppercase', fontSize: '0.8rem'}} className='text-center'><span style={{backgroundColor: '#ff7300', padding: '0.3rem 1rem', borderRadius: '20px'}}><MdOutlineRocketLaunch style={{color: '#f5f5f5', marginRight: '0.5rem', fontSize: '1rem'}}/> Saia na frente</span></p>
                  <MDBRow className=''>
                    <MDBCol md={5} className='mt-2'>
                      <MDBBtn onClick={() => toggleShow()} style={{width: '100%', height: '4rem', textTransform: 'initial', fontSize: '1.05rem', backgroundColor: '#ff7300'}}><BsCalendar2Date size={'1.3rem'} />&nbsp; Agendar visita</MDBBtn>
                    </MDBCol>
                    <MDBCol md={5} className='mt-2'>
                      <MDBBtn onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=5514998186060&text=Olá,%20tenho%20interesse%20no%20imóvel%20'+data.NOME+',%20Ref:%20'+data.ID+'.'} style={{width: '100%', height: '4rem', textTransform: 'initial', fontSize: '1.05rem', backgroundColor: '#25D366'}}><SiWhatsapp size={'1.3rem'} />&nbsp; Atendimento WhatsApp</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </MDBRow>
          </MDBContainer>  

         <MDBContainer>
          <MDBModal show={ModalAgendar} setShow={setModalAgendar} tabIndex='-1'>
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle style={{fontSize: '1.3rem', fontWeight: 600, textAlign: 'center', borderLeft: 'solid 0.5rem #ff7300', paddingLeft: '1rem'}}>Falar com um corretor</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={() => setModalAgendar(false)}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                { formstep === 'initial' ?
                  <div className='text-center'>     
                    <MDBRow>
                      <MDBCol style={{paddingTop: '0.5rem',}}>
                        <div className='text-center'>
                          <div className='d-flex justify-content-center'>
                            <div className='icon-circle'>
                                <span>
                                  <TiFlashOutline style={{fontSize: '2rem', color: '#f5f5f5'}} />
                                </span>
                              </div>                
                          </div>                          
                          <h4 className="h1-responsive text-center" style={{fontWeight: 700, color: '#212121', marginTop: '2rem'}}> 
                            Preencha o formulário abaixo e receba o contato de nossa equipe
                          </h4>
                          <p className="w-responsive text-center mx-auto mb-5 px-2" style={{fontWeight: 600, fontSize:'1rem',paddingLeft: '1rem', paddingRight: '1rem', color: '#ff6f01', marginTop: '2rem'}}>
                              Nossa equipe de sucesso cuidará de tudo para você!
                          </p>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className='justify-content-center align-items-center'>  
                      <MDBCol className='mb-1'>
                          <div className='text-center'>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                                        <BsPersonFill style={{fontSize: '1.5rem', color: '#ff6f01'}}/>
                                      </span>
                                    </div>
                                    <input onChange={e => setNome(e.target.value)} type="text" name="nome" id="nome" placeholder='seu nome completo' style={{borderRadius: '0px 30px 30px 0px'}} className="form-control" />
                                  </div> 
                                  <br />
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                                        <MdAlternateEmail style={{fontSize: '1.5rem', color: '#ff6f01'}}/>
                                      </span>
                                    </div>
                                    <input onChange={e => setEmail(e.target.value)} type="email" name="email" id="email" placeholder='seu e-mail' style={{borderRadius: '0px 30px 30px 0px'}} className="form-control" />
                                  </div>
                                  <br />
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                                          <IoPhonePortraitSharp style={{fontSize: '1.5rem', color: '#ff6f01'}} /> 
                                      </span>
                                    </div>
                                    <input onChange={e => setTelefone(e.target.value)} type="text" name="telefone" id="telefone" placeholder='seu telefone' style={{borderRadius: '0px 30px 30px 0px'}} className="form-control" />
                                  </div>
                                  <br />
                                  <div className="text-center mt-4">
                                  <MDBBtn onClick={() => enviarContato()} style={{borderRadius: '15px', fontWeight: 600, width: '100%'}} className='btn-v1' disabled={(nome === '' || email === '' || telefone === '' ? true : false)}>
                                    <BiMessageSquareCheck style={{fontSize: '1.3rem'}}/>&nbsp; Solicitar contato
                                  </MDBBtn>
                                  <p className='' style={{marginTop: '2.5rem', fontWeight: '600', fontSize: '0.9rem'}}>
                                    Preencha as informações acima, clique em <br />"Solicitar Contato" e pronto!
                                  </p>
                                  <p style={{fontSize: '0.6rem', fontWeight: 600}}>Ao clicar em "Solicitar Contato", você concorda com nossa <a href='/privacidade.pdf' target='_blank'>política de privacidade</a>.</p>
                                </div>
                          </div>
                      </MDBCol>
                    </MDBRow>
                  </div> 
                  : formstep === 'sending' ? 
                    <Loader />
                  : formstep === 'final' ? 
                  <MDBRow>
                    <MDBCol style={{paddingTop: '0.5rem',}}>
                      <div className='text-center'>
                        <div className='d-flex justify-content-center'>
                          <div className='icon-circle'>
                              <span>
                                <AiOutlineCheck style={{fontSize: '2rem', color: '#f5f5f5'}} />
                              </span>
                            </div>                
                        </div>                          
                        <h4 className="h1-responsive text-center" style={{fontWeight: 700, color: '#212121', marginTop: '2rem'}}> 
                          Recebemos sua solicitação!
                        </h4>
                        <p className="w-responsive text-center mx-auto mb-5 px-2" style={{fontWeight: 600, fontSize:'1rem',paddingLeft: '1rem', paddingRight: '1rem', color: '#ff6f01', marginTop: '2rem'}}>
                            Nossa equipe de sucesso entrará em contato com você!
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  : formstep === 'error' ? 
                  <MDBRow>
                    <MDBCol style={{paddingTop: '0.5rem',}}>
                      <div className='text-center'>
                        <div className='d-flex justify-content-center'>
                          <div className='icon-circle'>
                              <span>
                                <CgSandClock style={{fontSize: '2rem', color: '#f5f5f5'}} />
                              </span>
                            </div>                
                        </div>                          
                        <h4 className="h1-responsive text-center" style={{fontWeight: 700, color: '#212121', marginTop: '2rem', marginBottom: '2rem'}}> 
                          Estamos passando por uma manutenção!
                        </h4>
                        <span className='hover-shadow' onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=5514998186060'} style={{display: 'flex', justifyContent: 'center', backgroundColor: '#ff7300', borderRadius: '30px', paddingTop: '1rem', cursor: 'pointer'}}>
                          <span className='text-center' style={{backgroundColor: '#f5f5f5', borderRadius: '50%', width: '60px', height: '60px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                            <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <BsWhatsapp style={{color: '#ff6f00', fontSize: '1.5rem'}} />
                            </span>
                          </span> 
                          <span style={{fontSize: '1.5rem', paddingLeft: '1rem', fontWeight: 600, display: 'inline-block', color: '#f5f5f5'}}>
                            Fale com um corretor
                            <p style={{fontSize: '0.8rem', color: '#f5f5f5'}}>Clique aqui para iniciar uma conversa</p>
                          </span> 
                        </span>
                        <p className="w-responsive text-center mx-auto mb-5 px-2" style={{fontWeight: 600, fontSize:'1rem',paddingLeft: '1rem', paddingRight: '1rem', color: '#ff6f01', marginTop: '2rem'}}>
                            Nossa equipe de sucesso aguardo seu contato!
                        </p>

                      </div>
                    </MDBCol>
                  </MDBRow>
                  :
                    null
                }              
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </MDBContainer>
        </section>
        :
        <Loader />
        }
        <Footer />
      </>
  );

}

export default Imovel;
