import Radium, {StyleRoot} from 'radium';
import { pulse, fadeIn } from 'react-animations';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Logo from '../img/logo.svg';


function Loader () {

    const styles = {
        pulse: {
          animation: 'x  2s infinite',
          animationName: Radium.keyframes(pulse, 'pulse')
        },
      }
       

    return(
        <>
        <StyleRoot>
            <MDBContainer>
                <MDBRow className='justify-content-center' style={{padding: '7rem 0'}}>
                    <MDBCol size={12} className='text-center'>
                        <img src={Logo} width={'250px'} height={'auto'} alt='Logo Imóvel Valoriza' style={styles.pulse}/>
                    </MDBCol>
                    <MDBCol size={12}>
                        <p className='text-center' style={{ paddingTop: '2rem', fontWeight: 600,  width: '100%', animation: 'x 2s', animationName: Radium.keyframes(fadeIn, 'fadeIn'),}}>Aguarde alguns instantes enquanto carregamos o conteúdo para você!</p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </StyleRoot>
        </>
    );
}

function LoaderDash ({text, logoSize}) {

    const styles = {
        pulse: {
          animation: 'x  2s infinite',
          animationName: Radium.keyframes(pulse, 'pulse')
        },
      }
       

    return(
        <>
        <StyleRoot>
            <MDBContainer>
                <MDBRow className='justify-content-center' style={{padding: '7rem 0'}}>
                    <MDBCol size={12} className='text-center'>
                        <img src={Logo} width={logoSize !== undefined ? logoSize : '150px'} height={'auto'} alt='Logo Imóvel Valoriza' style={styles.pulse}/>
                    </MDBCol>
                    <MDBCol size={12}>
                        <p className='text-center' style={{ paddingTop: '2rem', fontWeight: 600,  width: '100%', animation: 'x 2s', animationName: Radium.keyframes(fadeIn, 'fadeIn'),}}>
                            {text !== undefined ? text : 'Aguarde alguns instantes...'}</p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </StyleRoot>
        </>
    );
}

export { Loader as default, LoaderDash };