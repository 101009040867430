import { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import './components.css';

function FeaturedLotes () {

    const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

    const [destaques, setDestaques] = useState([]);
    const temR = (string) =>  {
        if(string.indexOf('R$') === -1 ){
            return false;
        }else{
            return true;            
        }        
    }

    const removerR = (string) => {
        return string.replace('R$', '').trim();
    }

    const destaquesCharger = () => {
        fetch(api + "imoveis/destaques")
        .then((response) => response.json())
        .then((response) => {        
          setDestaques(response);
        })
        .catch((rejected) => {
          console.log(rejected);
        }); 
      }

      useEffect(() => {
        destaquesCharger();
      }, []);

    return (
    <>
    { destaques.destaques ?
        <>
        <MDBRow className="justify-content-center">
            {destaques.destaques.map(data => (
            <MDBCol md={3} key={data.ID} className="featured-lotes hover-shadow" onClick={() => window.location.href = "/buscar/imovel/" + (data.SLUG ? data.SLUG : data.ID)} style={{cursor: 'pointer', backgroundImage: 'url('+ api + 'images/' + data.ID + '/' + data.DESTAQUE_IMG.replace(/;/g, "") + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                <div>
                    <p style={{fontWeight: 600, fontSize: '0.7rem', textTransform: 'uppercase', color: '#f2f2f2', marginBottom: '0.7rem'}}>{data.CIDADE}</p>
                    <h3 style={{fontWeight: 500, color: '#f2f2f2'}}>{data.NOME}</h3>
                    <p style={{fontWeight: 600, fontSize: '0.8rem', textTransform: 'uppercase', color: '#f2f2f2', marginTop: '0.7rem', marginBottom: '1.3rem'}}>{data.DESTAQUE_ATRIBUTO_1}</p>
                    <p>
                        <span style={{backgroundColor: '#68db04', borderRadius: '7px', textTransform: 'uppercase', color: '#f2f2f2', padding: '0.7rem', fontSize: '0.75rem', fontWeight: 600}}>
                            {data.DESTAQUE_ATRIBUTO_2}
                        </span>
                    </p>
                    <p style={{fontWeight: 500, color: '#f2f2f2', fontSize: '0.8rem', marginBottom: '0.1rem', marginTop: '1.5rem'}}>{data.LEGENDA_VALOR}</p>
                    <h1 style={{fontWeight: 700, color: '#ff7300'}}>{temR(data.VALOR) ? <><span style={{fontSize: '1.5rem',}}>R$</span> {removerR(data.VALOR)}</> : data.VALOR} </h1>
                </div>            
            </MDBCol>
            ))}

        </MDBRow>
        <MDBRow className="justify-content-center" style={{marginTop: '2rem'}}>
            <MDBCol md={3}>
                <MDBBtn onClick={() => window.location.href='/buscar/'} style={{width: '100%', fontSize: '0.7rem', fontWeight: 600, backgroundColor: '#ff7300', borderRadius: '10px'}}>
                        Quero ver mais imóveis
                </MDBBtn>
            </MDBCol>
        </MDBRow>
        </>
        :
        null
    }
    </>
    );
}

export { FeaturedLotes };