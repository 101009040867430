import React from 'react';
import { MDBBtn, MDBContainer, MDBCol, MDBRow, MDBCardBody, MDBCard, } from 'mdb-react-ui-kit';
import './HomePage.css';
import { MdOutlineAlternateEmail, MdOutlinePassword } from 'react-icons/md';
import { IoLogIn } from 'react-icons/io5';
import Cookies from 'js-cookie';

const api = ( window.location.protocol === "https:" && window.location.hostname.substring(0, 4) !== "www." ? "https://imovelvaloriza.com.br/api/v2/" : "https://www.imovelvaloriza.com.br/api/v2/");

class Login extends React.Component {
  constructor(props) {
    super(props);   
    document.title = 'Área do Corretor | Imóvel Valoriza';
    Cookies.remove('id_adm');
    this.state = {
      warning: '',
      email: '',
      senha: '',
    }
  }  




  mudarpagina (pagina){
    window.location.href = '/' + pagina; 
  }
  

  ver_senha(senha,btn){
    if(document.getElementsByName(senha)[0].type === 'password'){
      document.getElementsByName(senha)[0].type = 'text';
      document.getElementsByName(btn)[0].innerText = 'Ocultar senha';
    }else{
      document.getElementsByName(senha)[0].type = 'password';
      document.getElementsByName(btn)[0].innerText = 'Mostrar senha';

    }
  }

  login(){

    const dataToSend = {
      mail: this.state.email,
      pass: this.state.senha,
    };
    
    const options = {
      method: "POST",
      body: JSON.stringify(dataToSend),
      mode: 'cors'
    };
    
    fetch(api + 'auth', options)
      .then(response => response.json())
      .then(response => { 
        if(response.status){
          Cookies.set('id_adm', response.authKey);
          this.mudarpagina('platform');
        }else{
          this.setState({warning: 'Ops, parece que houve um erro, por favor verifique seus dados e tente novamente.'});
        }
      })
      .catch(error => {
        console.error(error);  
        this.setState({warning: 'Ops, parece que houve um erro, por favor verifique seus dados e tente novamente.'});
      });
  }

  render() {
    return (
        <MDBContainer fluid style={{ height: '100%', width: '100%', paddingTop: '3rem', paddingBottom: '3rem', backgroundColor: '#ff7300' }}>
            <MDBRow className='justify-content-center align-items-center'>
              <MDBCol xl='5' className='text-center'>
                <MDBCard className='shadow-0' style={{borderRadius: '15px', padding: '2rem'}}>
                  <MDBCardBody className='text-center'  >
                  <div>
                    <div className='d-flex justify-content-center align-items-center'>
                      <span className='d-flex justify-content-center align-items-center' style={{width: '50px', height: '50px', backgroundColor: '#ff7300', borderRadius: '50%'}}>
                        <IoLogIn size={'2rem'} style={{color: '#f5f5f5'}} />
                      </span>
                    </div>
                    <h3 style={{color: '#ff7300', fontWeight: '700', marginTop: '2rem'}}>Seja Bem-vindo(a)</h3>
                  </div>
                    <p className='text-left' style={{marginTop: '2rem', marginBottom: '2.5rem', }}>
                          Preencha as informações abaixo, clique em <br />"Acessar Plataforma" e pronto!
                    </p>
                    <form onSubmit={e => e.preventDefault() }>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                              <MdOutlineAlternateEmail style={{color: '#ff7300'}} />
                            </span>
                          </div>
                          <input type="email" onChange={e => this.setState({email: e.target.value})} name='email' placeholder='seu e-mail' style={{borderRadius: '0px 30px 30px 0px'}} className="form-control" />
                        </div> 
                        <br />
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" style={{borderRadius: '30px 0px 0px 30px', backgroundColor: 'transparent'}}>
                              <MdOutlinePassword style={{color: '#ff7300'}} />
                            </span>
                          </div>
                          <input type="password" onChange={e => this.setState({senha: e.target.value})} name='senha' placeholder='sua senha' className="form-control" />
                          <div className="input-group-prepend">
                            <span className="input-group-text" style={{borderRadius: '0px 30px 30px 0px', backgroundColor: 'transparent'}}>
                               <a href='#ver' name='ver_senha' onClick={() => this.ver_senha('senha','ver_senha')} style={{fontSize:'0.8rem', fontWeight: '600', color: '#ff7300'}}>Mostrar Senha</a>
                            </span>
                          </div>                          
                        </div>
                        <div className="text-center mt-4">
                          <MDBBtn onClick={() => this.login()} style={{ borderRadius: '10px', fontWeight: '500', backgroundColor: '#ff7300', marginTop: '1rem' }} disabled= { this.state.email !== '' && this.state.senha !== '' ? false : true }>
                              Acessar Plataforma&nbsp;&nbsp;
                          </MDBBtn>         
                        </div>
                      <div>
                        <p style={{fontSize: '80%', color: 'red', marginTop: '1rem'}}>
                          <b>{this.state.warning}</b>
                        </p>
                      </div>
                    </form>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
  }
}

export default Login;
