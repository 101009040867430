import React, { useState } from 'react';
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarToggler, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, 
  MDBCollapse, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBListGroup, MDBListGroupItem , MDBBtn } from 'mdb-react-ui-kit';
import {  HiBars3BottomRight } from 'react-icons/hi2';
import { TbBuildingCommunity, TbHomeSearch } from 'react-icons/tb';
import { MdOutlineAttachMoney, MdShareLocation } from 'react-icons/md';
import { BiShapeSquare, BiHelpCircle } from 'react-icons/bi';
import { BsNewspaper, BsWhatsapp, } from 'react-icons/bs';
import Logo from '../img/logo.svg';

function NavBar() {
  const [menuModal, setMenuModal] = useState(false);

  const toggleShowMenu = () => setMenuModal(!menuModal);

  return (
    <>
      <MDBNavbar sticky expand='lg' light bgColor='light' className=''>
        <MDBContainer fluid>
          <MDBNavbarBrand href='/' style={{marginLeft: '3rem'}}>
            <img src={Logo} height='35' alt='' loading='lazy'/>          
          </MDBNavbarBrand>

          <MDBNavbarToggler onClick={() => toggleShowMenu()}>
            <HiBars3BottomRight style={{color: '#313131', fontSize: '2.5rem'}} />
          </MDBNavbarToggler>

            <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0 exc-mobile-nav' style={{fontWeight: 600, paddingRight: '4rem'}}>
              <MDBNavbarItem>
                <MDBNavbarLink href='/buscar'>
                    Buscar
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/buscar/all/comprar/all'>
                    Comprar
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/buscar/all/locação/all'>
                    Alugar
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/vender'>
                    Vender
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/incorporador'>
                    Incorporador
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='https://blog.imovelvaloriza.com.br'>
                    Blog
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>

        </MDBContainer>
      </MDBNavbar>
      <MDBModal show={menuModal} setShow={() => setMenuModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle style={{fontSize: '1.3rem', fontWeight: 600}}><BiHelpCircle style={{color: '#ff6f00', marginRight: '0.5rem', fontSize: '2rem'}} /> Como podemos te ajudar?</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => toggleShowMenu()}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>    
              <MDBListGroup style={{ minWidthL: '22rem' }} light>
              <MDBListGroupItem style={{padding:0}} onClick={() => window.location.href = '/buscar'}>
                  <span style={{display: 'flex',}}>
                    <span className='text-center' style={{backgroundColor: '#ff7300', borderRadius: '50%', width: '60px', height: '60px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <TbHomeSearch style={{color: '#ff6f00', fontSize: '1.5rem', color: '#f5f5f5'}} />
                      </span>
                    </span> 
                    <span style={{fontSize: '1.5rem', paddingLeft: '1rem', fontWeight: 600, display: 'inline-block'}}>
                      Buscar
                      <p style={{fontSize: '0.8rem'}}>Ache o imóvel ideal para você!</p>
                    </span> 
                  </span>                  
                </MDBListGroupItem>
                <MDBListGroupItem style={{padding:0, paddingTop: '1rem'}} onClick={() => window.location.href = '/buscar/all/comprar/all'}>
                  <span style={{display: 'flex',}}>
                    <span className='text-center' style={{backgroundColor: '#ff7300', borderRadius: '50%', width: '60px', height: '60px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <TbBuildingCommunity style={{color: '#ff6f00', fontSize: '1.5rem', color: '#f5f5f5'}} />
                      </span>
                    </span> 
                    <span style={{fontSize: '1.5rem', paddingLeft: '1rem', fontWeight: 600, display: 'inline-block'}}>
                      Comprar 
                      <p style={{fontSize: '0.8rem'}}>Ache sua casa própria!</p>
                    </span> 
                  </span>                  
                </MDBListGroupItem>
                <MDBListGroupItem style={{padding:0, paddingTop: '1rem' }} onClick={() => window.location.href = '/buscar/all/locação/all'}>
                  <span style={{display: 'flex',}}>
                    <span className='text-center' style={{backgroundColor: '#ff7300', borderRadius: '50%', width: '60px', height: '60px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <MdShareLocation style={{color: '#ff6f00', fontSize: '1.5rem', color: '#f5f5f5'}} />
                      </span>
                    </span> 
                    <span style={{fontSize: '1.5rem', paddingLeft: '1rem', fontWeight: 600, display: 'inline-block'}}>
                      Alugar
                      <p style={{fontSize: '0.8rem'}}>Ache o seu próximo lar!</p>
                    </span> 
                  </span>                  
                </MDBListGroupItem>
                <MDBListGroupItem style={{padding: 0, paddingTop: '1rem'}} onClick={() => window.location.href = '/vender'}>                  
                  <span style={{display: 'flex',}}>
                    <span className='text-center' style={{backgroundColor: '#ff7300', borderRadius: '50%', width: '60px', height: '60px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <MdOutlineAttachMoney style={{color: '#ff6f00', fontSize: '1.5rem', color: '#f5f5f5'}} />
                      </span>
                    </span> 
                    <span style={{fontSize: '1.5rem', paddingLeft: '1rem', fontWeight: 600, display: 'inline-block'}}>
                      Vender
                      <p style={{fontSize: '0.8rem'}}>Transforme seus imóveis em dinheiro!</p>
                    </span> 
                  </span>
                </MDBListGroupItem>
                <MDBListGroupItem style={{padding:0, paddingTop: '1rem'}} onClick={() => window.location.href = '/incorporador'}>
                  <span style={{display: 'flex',}}>
                    <span className='text-center' style={{backgroundColor: '#ff7300', borderRadius: '50%', width: '60px', height: '60px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <BiShapeSquare style={{color: '#ff6f00', fontSize: '1.5rem', color: '#f5f5f5'}} />
                      </span>
                    </span> 
                    <span style={{fontSize: '1.5rem', paddingLeft: '1rem', fontWeight: 600, display: 'inline-block'}}>
                      Incorporador
                      <p style={{fontSize: '0.8rem'}}>Tudo para viabilizar seu empreendimento!</p>
                    </span> 
                  </span>
                </MDBListGroupItem>
                <MDBListGroupItem style={{padding:0, paddingTop: '1rem'}} onClick={() => window.location.href = 'https://blog.imovelvaloriza.com.br'}>
                  <span style={{display: 'flex',}}>
                    <span className='text-center' style={{backgroundColor: '#ff7300', borderRadius: '50%', width: '60px', height: '60px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <BsNewspaper style={{color: '#ff6f00', fontSize: '1.5rem', color: '#f5f5f5'}} />
                      </span>
                    </span> 
                    <span style={{fontSize: '1.5rem', paddingLeft: '1rem', fontWeight: 600, display: 'inline-block'}}>
                      Blog 
                      <p style={{fontSize: '0.8rem'}}>Fique por dentro de tudo, conheça o blog!</p>
                    </span> 
                  </span>
                </MDBListGroupItem>
                <MDBListGroupItem style={{padding:0, paddingTop: '1rem'}} onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=5514998186060'}>
                  <span style={{display: 'flex',}}>
                    <span className='text-center' style={{backgroundColor: '#ff7300', borderRadius: '50%', width: '60px', height: '60px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <BsWhatsapp style={{color: '#ff6f00', fontSize: '1.5rem', color: '#f5f5f5'}} />
                      </span>
                    </span> 
                    <span style={{fontSize: '1.5rem', paddingLeft: '1rem', fontWeight: 600, display: 'inline-block'}}>
                      Fale com um corretor
                      <p style={{fontSize: '0.8rem'}}>Estamos aqui para ajudar você!</p>
                    </span> 
                  </span>
                </MDBListGroupItem>
              </MDBListGroup>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default NavBar;
